import React from 'react';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon } from "react-share";
import { RWebShare } from 'react-web-share';
import { Button } from 'react-bootstrap';


export function ShareLinkSocialButtons({url, buttonText, shareMessage, emailBody}) {
  return (
    <>
      <div className="d-none d-md-inline-block">
        <FacebookShareButton className="me-1" url={url} quote={shareMessage + " - "}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton className="me-1" url={url} title={shareMessage + " - "}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <EmailShareButton url={url} subject={shareMessage} body={emailBody}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>

      <div className="d-md-none">
        <RWebShare data={{
          text: shareMessage, url: url, title: shareMessage}} >
          {buttonText ?
            <Button className="standard-btn">{buttonText}</Button>
          :
            <i className="icon-as-link fa fa-share-nodes fa-lg" />
          }
        </RWebShare>
      </div>
    </>
  )
}