import JobRewardSummary from "./JobRewardSummary";

const JobReward = ({ reward }) => {
  return (
    <div>
      <JobRewardSummary reward={reward} />
      {reward?.estimated_value &&
        <p className="mt-1 mb-0 small">
          Estimated value: {parseFloat(reward.estimated_value).toFixed(2)}
        </p>
      }
      {reward?.url &&
        <p className="mt-1 mb-0 small">
          <a href={reward.url} target="_blank" rel="noreferrer">Details</a>
        </p>
      }
      {reward?.description && <p className="mt-0 mb-0 small">{reward.description}</p>}
    </div>
  );
}

export default JobReward;