import axios from "axios";
import { AsyncTypeahead, Menu, MenuItem, Hint } from 'react-bootstrap-typeahead';
import React, { useState, useEffect } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

function AutocompleteLocationInput({ formData, setFormData, handleSubmit }) {
  const [isLoading, setIsLoading] = useState(false);
  const [jobLocations, setJobLocations] = useState([]);

  useEffect(() => {
    if (jobLocations[0] !== undefined) {
      setFormData({ ...formData, where_search_text: jobLocations[0].full_text });
    }
  }, [jobLocations])

  function handleSearch(query) {
    setIsLoading(true);
    setFormData({ ...formData, where_search_text: query })

    axios.get(`/api/v1/locations/autocomplete`, { params: { location_query: query }}).then(
      (response) => {
        setJobLocations(response.data.result);
        setIsLoading(false);
      },
      (error) => {
        setJobLocations([]);
        setIsLoading(false);
      }
    );
  };

  function handleOnChange(selectedLocations) {
    let selectedLocation = selectedLocations[0];
    if(selectedLocation) {
      setFormData({ ...formData, where_search_text: selectedLocation.full_text })
    }
  }

  function handleOnInputChange(text) {
    if(text.trim() === '') {
      setFormData({ ...formData, where_search_text: '' })
    }
  }

  return (
      <AsyncTypeahead
        id="autocomplete-location-search"
        defaultInputValue={formData.where_search_text}
        onSearch={handleSearch}
        onInputChange={(text) => handleOnInputChange(text)}
        onChange={(selectedLocations) => handleOnChange(selectedLocations)}
        options={jobLocations}
        placeholder="Where? (e.g. Boston, Remote, Antarctica)"
        delayed={200}
        filterBy={() => true}
        isLoading={isLoading}
        labelKey="full_text"
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
          <Hint>
            <FloatingLabel className="mb-3" controlId="where-job-search" label={["Where? ", <span key="where-example" className="floating-label-sm">(e.g. Remote, Boston, Maine) </span>]}>
              <Form.Control {...inputProps} ref={(input) => {
                  inputRef(input);
                  referenceElementRef(input);
                }}
              />
              { handleSubmit ?
                <i className="inline-icon icon-btn p-2 fa fa-map-marker" onClick={e => handleSubmit(e)} />
              :
                <i className="inline-icon p-2 fa fa-map-marker" />
              }
            </FloatingLabel>
          </Hint>
        )}
        renderMenu={(results, {newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps},) => (
          <Menu {...menuProps}>
            {results.map((result, index) => (
              <MenuItem key={index} option={result} position={index}>
                <strong>{result.main_text}</strong> <small>{result.secondary_text}</small>
              </MenuItem>
            ))}

            {results.length === 0
            ?
              <MenuItem disabled role="option">
                No matches found.
              </MenuItem>
            :
              <MenuItem disabled className="mx-2 text-end">
                <img src="https://developers.google.com/places/documentation/images/powered-by-google-on-white.png" alt="Powered by Google" />
              </MenuItem>
            }
          </Menu>
        )}
      />
  );
};

export default AutocompleteLocationInput;