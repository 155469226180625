import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AffiliateLinkForm from './AffiliateLinkForm';

const NewAffiliateLink = () => {

  return (
    <Container>
      <Col className="my-3">
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/affiliate_links' }}>Affiliate Links</Breadcrumb.Item>
              <Breadcrumb.Item active>New</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col sm={12}>
            <AffiliateLinkForm />
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default NewAffiliateLink;