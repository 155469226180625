import axios from 'axios';
import { Modal, Form, Button, InputGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { closeModal } from '../../actions/modalActions';
import { fullShareLink } from "../../utils/LinkUtils";
import { ShareLinkSocialButtons } from "../links/ShareLinkSocialButtons";

function ShareJobModal({...props}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shareLink, setShareLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    fetchShareLink(props.job.id);
  }, [props.job.id]);

  function fetchShareLink(job_id) {
    axios.get(`/api/v1/jobs/${job_id}/share_link_key`)
    .then(
      (response) => {
        setShareLink(fullShareLink(response.data.share_link_key));
      },
      (error) => {
        setShareLink("");
      }
    );
  }

  function handleCopy(event) {
    navigator.clipboard.writeText(shareLink);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }

  function handleHide(event) {
    dispatch(closeModal());
  }

  const tooltip = (
    <Tooltip>Copied!</Tooltip>
  );

  function handleMyShareLinksClick(event) {
    handleHide();
    navigate('/my_share_links');
  }

  return (
    <div>
      <Modal show={true} onHide={e => handleHide(e)} fullscreen="md-down">
        <Modal.Header closeButton>Share job: {props.job.title}</Modal.Header>
        <Modal.Body>
          <InputGroup>
            <Form.Control type="text" readOnly value={shareLink}/>
            <OverlayTrigger placement="top" overlay={isCopied ? tooltip : <></>}>
              <Button className="standard-btn" onClick={e => handleCopy(e)}>
                {isCopied ?
                  <i className="fa fa-check"></i>
                :
                  <i className="fa fa-copy"></i>
                }
              </Button>
            </OverlayTrigger>
          </InputGroup>
          <Form.Text muted>Remember, your friend must apply using this link and be hired in order for you to qualify for the referral bonus.</Form.Text>
          <div className="mt-3">
            <ShareLinkSocialButtons url={shareLink}
              buttonText='Share Link'
              shareMessage={"Check out this job I found on Magnetworks"}
              emailBody={"I think you may be interested in this job I found on Magnetworks: "}
            />
          </div>
          <hr/>
          <Button className="standard-btn" onClick={e => handleMyShareLinksClick(e)}>My Share Links</Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ShareJobModal;