import { Button, Row, Col } from 'react-bootstrap';

function CompletionConfirmationStep({resolution, ...props}) {
  return (
    <div>
      <Row>
        <Col className="mt-2 text-center" xs={12}>
          <h5>Resolution Summary</h5>
        </Col>
        <Col className="mt-2" xs={12}>
          { resolution.auto_completed ?
            <>
              <div><strong>Automatically closed by Magnetworks</strong></div>
              <div>Opening filled? <strong>N/A</strong></div>
              <div>Found applicant via Magnetworks? <strong>N/A</strong></div>
              <div>Applicant selected? <strong>N/A</strong></div>
              <div>Payment confirmed? <strong>N/A</strong></div>
            </>
          :
            <>
              <div>Opening filled? <strong>{resolution.is_position_filled ? 'Yes' : 'No'}</strong></div>
              <div>Found applicant via Magnetworks? <strong>{resolution.is_position_filled ? (resolution.is_mw_hire ? 'Yes' : 'No') : 'N/A'}</strong></div>
              <div>Applicant selected? <strong>{resolution.is_mw_hire ? (resolution.found_applicant ? 'Yes' : 'No') : 'N/A'}</strong></div>
              <div>Payment confirmed? <strong>{resolution.is_mw_hire ? (resolution.status === 'complete' ? 'Yes' : 'No') : 'N/A'}</strong></div>
            </>
          }

        </Col>
        <Col xs={12} className="mt-3 mb-3">
          <Button onClick={() => props.closeWizard()} className="standard-btn full-btn">Close</Button>
        </Col>
      </Row>
    </div>
  );
}

export default CompletionConfirmationStep;