import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import EmployerNotFound from './EmployerNotFound';
import EmployersPublicProfileHeader from './EmployersPublicProfileHeader';

function EmployersPublicLayout({component: Component, ...props}) {
  const { business_id } = useParams();
  const [business, setBusiness] = useState(null);
  const [isLoadingBusiness, setIsLoadingBusiness] = useState(true);

  useEffect(() => {
    if (business_id && business === null) {
      fetchBusiness();
    }
  }, [business_id, business]);

  function fetchBusiness() {
    axios.get(`/api/v1/businesses/${business_id}/public_profile`).then(
      (response) => {
        setBusiness(response.data);
        setIsLoadingBusiness(false);
      },
      (error) => {
        setIsLoadingBusiness(false);
      }
    );
  }

  return (
    <>
      {isLoadingBusiness ?
        <></>
      : business ?
        <>
          <EmployersPublicProfileHeader business={business} />
          <Component businessId={business_id} />
        </>
      :
        <EmployerNotFound />
      }
    </>
  );
}

export default EmployersPublicLayout;