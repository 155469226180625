import axios from 'axios';

export const clearError = () => dispatch => {
  dispatch({type: 'SET_AUTH_ERROR', payload: null})
};

export const signinUser = (credentials) => {
  return (dispatch) => {
    return axios.post('/auth/login', credentials)
    .then(response => {
      dispatch({type: 'AUTHENTICATED', payload: response.data})
    }, error => {
      dispatch({type: 'NOT_AUTHENTICATED', payload: error.response})
    });
  };
};

export const signoutUser = () => {
  return (dispatch) => {
    return axios.get('/auth/logout')
    .then(response => {
      dispatch({type: 'NOT_AUTHENTICATED'})
    }, error => {
      dispatch({type: 'NOT_AUTHENTICATED'})
    });
  }

}

export const verifyToken = () => {
  return (dispatch) => {
    return axios.get('/auth/verify_token')
    .then(response => {
      dispatch({type: 'AUTHENTICATED', payload: response.data})
    }, error => {
      dispatch({type: 'NOT_AUTHENTICATED'})
    });
  };
};

export const registerUser = (data) => {
  return (dispatch) =>
  {
    return axios.post('/auth/register', data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      dispatch(signinUser({ email: data.get('email'), password: data.get('password')}));
    }, error => {
      dispatch({type: 'SET_AUTH_ERROR', payload: error.response})
    });
  };
};