import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { registerUser } from '../../actions/authActions';
import { closeModal } from '../../actions/modalActions';
import { can } from '../../services/ability';
import { GetFeedbackMessageFor } from '../../utils/FormUtils';
import { FileUploadInput } from '../inputs/FileUploadInput';
import AddressForm from './AddressForm';
import PhoneInput from '../inputs/PhoneInput';

function SignUpForm({ ...props }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useSelector((state) => state.authData);
  const [requiredAddress, setRequiredAddress] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    password: "",
    role_name: props.roleName,
    business: { name: "", employer_size_id: "", logo: null },
    required_credit_card: false,
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [showPassowrd, setShowPassword] = useState(false);
  const [employerSizes, setEmployerSizes] = useState([]);

  function toggleShowPassword() {
    setShowPassword(!showPassowrd);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setShowPassword(false);
    setisLoading(true);

    const finalFormData = new FormData(event.target);
    const businessData = formData.business;
    const businessAddressData = formData.business.address_attributes;

    for (const k in formData) {
      if(k === 'business')
        continue;

      finalFormData.append(`${k}`, formData[k] ?? "" );
    }

    for (const k in businessData) {
      if (k === 'address_attributes')
        continue;

      finalFormData.append(`business[${k}]`, businessData[k] ?? "");
    }

    for (const k in businessAddressData) {
      finalFormData.append(`business[address_attributes][${k}]`, businessAddressData[k] ?? "");
    }

    return dispatch(registerUser(finalFormData));
  }

  useEffect(() => {
    // We are rendering this component twice on one page, once for the candidate/referrer sign up and once for the employer sign up.
    // This useEffect is called for both components when either form is submitted because authData is a globally shared state.
    // By checking isLoading first, we ensure that we are only only operating on the state for the appropriate form component.
    if (isLoading) {
      setisLoading(false);

      if (authData.loggedIn) {
        dispatch(closeModal());

        if (can(authData, "read", "employers_dashboard")) {
          navigate("/employers/membership_signup");
        }

        if(!can(authData, "read", "employers_dashboard") && props.isModal === false) {
          navigate("/jobs");
        }
      }

      if (authData.error) {
        let error = authData.error.data.error;

        if (error !== undefined) {
          setErrorMessage(error);

        } else {
          setErrorMessage("An unknown error has occurred.");
        }
      }
    }

    if(!authData.error) {
      setErrorMessage("");
    }
  }, [authData, dispatch]);

  useEffect(() => {
     props.roleName === "Employer" && fetchEmployerSizes()
  }, []);

  function fetchEmployerSizes() {
    axios.get("/api/v1/employer_sizes").then(
      (response) => {
        setEmployerSizes(response.data);
      },
      () => {
        setEmployerSizes([]);
      }
    );
  }

  return (
    <div>
      {typeof errorMessage !== "object" && errorMessage && (
        <Alert variant="danger">{errorMessage}</Alert>
      )}

      <Form onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <Col xs={12} className="text-center pb-2">
            <Form.Text className="text-muted mb-3">All fields marked with <span className="error-text">*</span> are required.</Form.Text>
          </Col>
          {formData.role_name === "Employer" ? (
            <div>
              <Col xs={12}>
                <FloatingLabel controlId="business_name" label="Business Name" className="mb-3 required">
                  <Form.Control type="text" placeholder="Business Name" value={formData.business.name} isInvalid={errorMessage["businesses.name"]}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        business: {
                          ...formData.business,
                          name: e.target.value,
                        },
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {GetFeedbackMessageFor(errorMessage, "businesses.name")}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Save Business Address</Form.Label>
                  <Form.Check type="switch" checked={requiredAddress}
                    onChange={(e) => {
                      setRequiredAddress(e.target.checked);
                      setFormData({
                        ...formData,
                        business: {
                          ...formData.business,
                          address_attributes: e.target.checked ? {address_one: '', address_two: '', city: '', state_id: '', zip: ''} : null,
                        }
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              {requiredAddress && <AddressForm formDataPrefix="business" errorKeyPrefix="businesses." additionalRequiredFields={['address_one']} isModal={props.isModal} {...{ formData, setFormData, errorMessage }} />}
              <Col xs={12}>
                <Form.Group controlId="business_logo" className="mb-3">
                  <Form.Label>Business Logo</Form.Label>
                  <FileUploadInput
                    onChange={(e) => setFormData({...formData, business: {...formData.business, logo: e.target.files[0]}})}
                    isInvalid={errorMessage["businesses.logo"]}
                    errorMessage={GetFeedbackMessageFor(errorMessage, "businesses.logo")}
                    hintText="Allowed file types are jpg, jpeg, png"
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="employer_size">
                  <FloatingLabel controlId="employer_size" label="Number of Employees" className="mb-3 required">
                    <Form.Select aria-label="Number of Employees" value={formData?.business?.employer_size_id} isInvalid={errorMessage["businesses.employer_size"]}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          business: {
                            ...formData.business,
                            employer_size_id: e.target.value,
                          },
                        })
                      }
                    >
                      <option value="">Select an option</option>
                      {employerSizes.map((employerSize) => {
                        return (
                          <option key={employerSize.id} value={employerSize.id}>
                            {employerSize.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {GetFeedbackMessageFor(errorMessage, "businesses.employer_size")}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </div>
          ) : null}
          <Col xs={12}>
            <FloatingLabel controlId="email" label="Email" className="mb-3 required">
              <Form.Control type="text" placeholder="Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} isInvalid={errorMessage.email} />
              <Form.Control.Feedback type="invalid">
                {GetFeedbackMessageFor(errorMessage, "email")}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>
          {props.roleName === "Employer" ?
            <>
              <Col xs={12}>
                <FloatingLabel controlId="first_name" label="First Name" className="mb-3">
                  <Form.Control type="text" placeholder="First Namee" value={formData.first_name} onChange={(e) => setFormData({ ...formData, first_name: e.target.value })} isInvalid={errorMessage.first_name} />
                  <Form.Control.Feedback type="invalid">
                    {GetFeedbackMessageFor(errorMessage, "first_name")}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel controlId="last_name" label="Last Name" className="mb-3">
                  <Form.Control type="text" placeholder="Last Name" value={formData.last_name} onChange={(e) => setFormData({ ...formData, last_name: e.target.value })} isInvalid={errorMessage.last_name} />
                  <Form.Control.Feedback type="invalid">
                    {GetFeedbackMessageFor(errorMessage, "last_name")}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel controlId="phone" label="Phone" className={"mb-3 " + (formData.role_name === "Employer" ? "required" : "")}>
                  <PhoneInput placeholder="Phone" alwaysShowMask={true} value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} isInvalid={errorMessage.phone} />
                  <Form.Control.Feedback type="invalid">
                    {GetFeedbackMessageFor(errorMessage, "phone")}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </>
          :
            <></>
          }
          <Col xs={12}>
            <FloatingLabel controlId="password" label="Password" className="mb-3 required">
              <Form.Control autoComplete="new-password" className="password-input" type={showPassowrd ? "text" : "password"} placeholder="Password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} isInvalid={errorMessage.password} />
              <Form.Control.Feedback type="invalid">
                {GetFeedbackMessageFor(errorMessage, "password")}
              </Form.Control.Feedback>
              <i className={showPassowrd ? "inline-icon icon-btn p-2 fa fa-eye-slash" : "inline-icon icon-btn p-2 fa fa-eye"} onClick={toggleShowPassword} />
              <small className="text-muted">
                Use 8 or more characters with a mix of letters & numbers
              </small>
            </FloatingLabel>
          </Col>
          <Col xs={12}>
            <Button type="submit" className="standard-btn full-btn" disabled={isLoading}>
              {isLoading === true ?
                <i className="fa fa-spinner fa-spin"/>
               :
                "Sign Up"
              }
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default SignUpForm;
