import axios from 'axios';
import { useState, useEffect } from 'react';
import { Container, Table, Button, Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import AffiliateLinkTableEntry from './AffiliateLinkTableEntry';

const AffiliateLinks = () => {
  const [affiliateLinks, setAffiliateLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios.get('/api/v1/affiliate_links')
      .then((response) => {
        setAffiliateLinks(response.data);
      })
      .catch((error) => {
        setErrorMessage('Error loading affiliate links.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Container className="pt-3">
      <Row>
        <Col md={6}>
          <h3>Affiliate Links</h3>
        </Col>
        <Col md={6} className="text-end">
          <Button as={Link} to={'/admin/affiliate_links/new'} className="standard-btn">New Affiliate Link</Button>
        </Col>
      </Row>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {errorMessage ?
            <Row className="mt-3">
              <Col>
                <Alert variant="danger">{errorMessage}</Alert>
              </Col>
            </Row>
            :
            <>
              {affiliateLinks.length === 0 ?
                <Row className="mt-3">
                  <Col>
                    <Alert variant="warning">There are no affiliate links yet.</Alert>
                  </Col>
                </Row>
                :
                <div className="table-responsive table-scrollable table-sticky-header">
                  <Table hover>
                    <thead>
                      <tr className="p-2">
                        <th>ID</th>
                        <th>Name</th>
                        <th>Key</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {affiliateLinks.map((affiliateLink) => (
                        <AffiliateLinkTableEntry key={affiliateLink.id} affiliateLink={affiliateLink} />
                      ))}
                    </tbody>
                  </Table>
                </div>
              }
            </>
          }
        </>
      )}
    </Container>
  );
}

export default AffiliateLinks;