import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Col, Row, Table } from 'react-bootstrap';
import { format } from 'date-fns'

import useGetApi from '../hooks/useApiGet';
import { addGlobalAlert } from "../actions/globalAlertActions";
import { formatPhoneNumber } from "../utils/PhoneUtils";

function MyApplications() {
  const dispatch = useDispatch();
  const [myApplications, setMyApplications] = useState([]);
  const {data, error, loading} = useGetApi({url: '/api/v1/me/applications'});

  useEffect(() => {
    if (!loading) {
      if(data)
        setMyApplications(data);

      if(error)
        dispatch(addGlobalAlert({message: error.response.data.error || 'An unknown error has occurred.', alertType: 'danger'}));
    }
  }, [loading]);

  return (
    <Col className="col-12 my-3">
      <Row>
        <Col className="col-12 px-4">
          <Breadcrumb>
            <Breadcrumb.Item active>My Applications</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <div className="table-responsive table-scrollable table-sticky-header px-2">
            <Table hover>
              <thead>
                <tr className="p-2">
                  <th>Job title</th>
                  <th>Job status</th>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Applied at</th>
                  <th className="text-center">Applicants</th>
                </tr>
              </thead>
              <tbody>
                {myApplications.map(application => {
                  return <tr key={application.id}>
                    {application.job_active ?
                      <>
                        <td><Link className="dark-link" to={`/jobs/${application.job_id}`}>{application.job_title}</Link></td>
                        <td>Active</td>
                      </>
                      :
                      <>
                        <td>{application.job_title}</td>
                        <td>Inactive</td>
                      </>
                    }
                    <td>{application.first_name}</td>
                    <td>{application.last_name}</td>
                    <td>{application.email}</td>
                    <td>{formatPhoneNumber(application.phone)}</td>
                    <td>{format(new Date(application.created_at), 'MM/dd/yyyy h:mm a')}</td>
                    <td className="text-center">{application.applications_count}</td>
                  </tr>
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Col>
  );
}

export default MyApplications;