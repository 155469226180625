import { Breadcrumb, Col, Row, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import useGetApi from '../hooks/useApiGet';
import { addGlobalAlert } from '../actions/globalAlertActions';
import { fullShareLink } from '../utils/LinkUtils';
import { ShareLinkSocialButtons } from './links/ShareLinkSocialButtons';

function MyShareLinks() {
  const dispatch = useDispatch();
  const [myShareLinks, setMyShareLinks] = useState([]);
  const {data, error, loading} = useGetApi({url: '/api/v1/me/share_links'});
  const [showCopied, setShowCopied] = useState({});

  useEffect(() => {
    if (!loading) {
      if(data)
        setMyShareLinks(data);

      if(error)
        dispatch(addGlobalAlert({message: error.response.data.error || 'An unknown error has occurred.', alertType: 'danger'}));
    }
  }, [loading]);

  function handleCopy(event, key) {
    let targetClassList = event.target.classList;

    setShowCopied({...showCopied, [key]: true});
    targetClassList.add('fa-check');
    targetClassList.remove('fa-copy');
    targetClassList.remove('icon-as-link');

    navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/s/${key}`);

    setTimeout(() => {
      targetClassList.remove('fa-check');
      targetClassList.add('fa-copy');
      targetClassList.add('icon-as-link');
      setShowCopied({...showCopied, [key]: false});
    }, 1500);
  }

  return (
    <Col className="col-12 my-3">
      <Row>
        <Col className="col-12 px-4">
          <Breadcrumb>
            <Breadcrumb.Item active>My Share Links</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <div className="table-responsive table-scrollable table-sticky-header px-2">
            <Table hover>
              <thead>
                <tr className="p-2">
                  <th>Job title</th>
                  <th>Job status</th>
                  <th className="text-center">Views</th>
                  <th className="text-center">Applies</th>
                  <th className="text-center">Share</th>
                </tr>
              </thead>
              <tbody>
                {myShareLinks.map(link => {
                  return <tr key={link.id}>
                    {link.job_active ?
                      <>
                        <td><Link className="dark-link" to={`/jobs/${link.job_id}`}>{link.job_title}</Link></td>
                        <td>Active</td>
                      </>
                    :
                      <>
                        <td>{link.job_title}</td>
                        <td>Inactive</td>
                      </>
                    }
                    <td className="text-center">{link.views_count}</td>
                    <td className="text-center">{link.applications_count}</td>
                    <td className="text-center">
                      {link.job_active ?
                        <>
                          <div className="d-none d-md-inline-block">
                            <OverlayTrigger key={link.id} placement="top" overlay={showCopied[link.share_link_key] ? <Tooltip>Copied!</Tooltip> : <></>}>
                              <span className="fa-stack fa-1x text-secondary">
                                <i className="fas fa-stack-2x fa-circle"></i>
                                <i className="icon-as-link fa fa-stack-1x fa-copy" onClick={e => handleCopy(e, link.share_link_key)} style={{color: "#FFFFFF"}}></i>
                              </span>
                            </OverlayTrigger>
                          </div>
                          <ShareLinkSocialButtons url={fullShareLink(link.share_link_key)}
                            shareMessage={"Check out this job I found on Magnetworks"}
                            emailBody={"I think you may be interested in this job I found on Magnetworks: "}
                          />
                        </>
                      :
                        null
                      }

                    </td>
                  </tr>
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Col>
  );
}

export default MyShareLinks;