import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from '../../actions/authActions';
import { closeModal } from '../../actions/modalActions';

import SignUpFormWrapper from '../SignUpFormWrapper';

function SignUpModal({ ...props }) {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authData);

  function handleHide(event) {
    clearErrors();
    dispatch(closeModal());
  }

  function clearErrors() {
    if (authData.error) {
      dispatch(clearError());
    }
  }

  return (
    <div>
      <Modal show={true} onHide={e => handleHide(e)} fullscreen="md-down">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <SignUpFormWrapper {...props}/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SignUpModal;