import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import useApiGet from '../../hooks/useApiGet';
import RewardSourceForm from './RewardSourceForm';

const EditRewardSource = () => {
  const params = useParams();
  const rewardSource = useApiGet({url: `/api/v1/reward_sources/${params.id}`}).data;

  return (
    <Container>
      <Col className="my-3">
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/reward_sources' }}>Reward Sources</Breadcrumb.Item>
              <Breadcrumb.Item active>{rewardSource?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col sm={12}>
            <RewardSourceForm rewardSource={rewardSource} />
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default EditRewardSource;