
import { Row, Col, Form } from "react-bootstrap";
import produce from "immer";

import { GetFeedbackMessageFor } from "../../utils/FormUtils";
import useGetApi from '../../hooks/useApiGet';

function AddressForm({
  formData,
  setFormData,
  isDisabled = false,
  errorMessage,
  formDataPrefix = "",
  errorKeyPrefix = "",
  additionalRequiredFields = [],
  storeFormData,
  ...props
}) {
  const states = useGetApi({ url: '/api/v1/states' }).data || [];
  const requiredFields = ['city', 'state', 'zip'].concat(additionalRequiredFields);

  // formDataPrefix and formDataPrefixVector represent how many levels deep within the formData the address attributes are and should be set.
  // For example a formDataPrefix of "business.job" would assume the following structure: {business: {job: {address_attributes: {}}}
  // Not setting a formDataPrefix assumes the address_attributes are at the root of the formData: {address_attributes: {}}
  const formDataPrefixVector = formDataPrefix ? formDataPrefix.split(".") : undefined;

  function isRequired({ key }) {
    return requiredFields.includes(key);
  }

  function setValue({ key, value }) {
    setFormData(prevState => {
      const newState = produce(prevState, draft => {
        draft = formDataPrefixVector ? formDataPrefixVector.reduce((previous, current) => previous[current], draft) : draft;
        draft.address_attributes[key] = value;
      });

      if(storeFormData)
        storeFormData(newState);

      return newState;
    });
  };

  function getValue({ key, isAssociation = false }) {
    const rootData = formDataPrefixVector ? formDataPrefixVector.reduce((previous, current) => previous[current], formData) : formData;
    if (isAssociation) {
      return rootData?.address_attributes?.[key + '_id'];
    }
    else {
      return rootData?.address_attributes?.[key];
    }
  }

  function isInvalid({ key, isAssociation = false }) {
    let val = getValue({ key: key, isAssociation: isAssociation });
    let hasGeocodeError = errorMessage[errorKeyPrefix + "address.geocode"] && (isRequired({ key: key }) || val.length > 0);
    let hasKeySpecificError = errorMessage[errorKeyPrefix + "address." + key];

    return hasKeySpecificError || hasGeocodeError;
  }

  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3 mt-3" controlId="address_one">
            <Form.Label className={isRequired( {key: 'address_one'} ) && 'required'}>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              value={getValue({ key: 'address_one' })}
              onChange={(e) => setValue({ key: 'address_one', value: e.target.value })}
              isInvalid={isInvalid({ key: 'address_one'} )}
              disabled={isDisabled}
            />
            <Form.Control.Feedback type="invalid">
              {GetFeedbackMessageFor(errorMessage, errorKeyPrefix + "address.address_one")}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Control.Feedback type="invalid">
            {GetFeedbackMessageFor(errorMessage, errorKeyPrefix + "address.address_one")}
          </Form.Control.Feedback>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3 mt-3" controlId="address_two">
            <Form.Label className={isRequired({ key: 'address_two' }) && 'required'}>Address 2</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              value={getValue({ key: 'address_two' })}
              onChange={(e) => setValue({ key: 'address_two', value: e.target.value })}
              isInvalid={isInvalid({ key: 'address_two' })}
              disabled={isDisabled}
            />
            <Form.Control.Feedback type="invalid">
              {GetFeedbackMessageFor(errorMessage, errorKeyPrefix + "address.address_two")}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={props.isModal ? 12 : 5}>
          <Form.Group className="mb-3 mt-3" controlId="city">
            <Form.Label className={isRequired({ key: 'city' }) && 'required'}>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              value={getValue({ key: 'city' })}
              onChange={(e) => setValue({ key: 'city', value: e.target.value })}
              isInvalid={isInvalid({ key: 'city' })}
              disabled={isDisabled}
            />
            <Form.Control.Feedback type="invalid">
              {GetFeedbackMessageFor(errorMessage, errorKeyPrefix + "address.city")}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={props.isModal ? 8 : 4}>
          <Form.Group className="mb-3 mt-3" controlId="state">
            <Form.Label className={isRequired({ key: 'state' }) && 'required'}>State</Form.Label>
            <Form.Select
              aria-label="State"
              value={getValue({ key: 'state_id' })}
              onChange={(e) => setValue({ key: 'state_id', value: e.target.value })}
              isInvalid={isInvalid({ key: 'state', isAssociation: true })}
              disabled={isDisabled}
            >
              <option value="">Select a State</option>
              {states.map((state) => {
                return <option key={state.id} value={state.id}>{state.name}</option>;
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {GetFeedbackMessageFor(errorMessage, errorKeyPrefix + "address.state")}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={props.isModal ? 4 : 3}>
          <Form.Group className="mb-3 mt-3" controlId="zip">
            <Form.Label className={isRequired({ key: 'zip' }) && 'required'}>Zip</Form.Label>
            <Form.Control
              type="text"
              placeholder="Zip Code"
              value={getValue({ key: 'zip' })}
              onChange={(e) => setValue({ key: 'zip', value: e.target.value })}
              isInvalid={isInvalid({ key: 'zip' })}
              disabled={isDisabled}
            />
            <Form.Control.Feedback type="invalid">
              {GetFeedbackMessageFor(errorMessage, errorKeyPrefix + "address.zip")}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {errorMessage[errorKeyPrefix + "address.geocode"] ? <p className="text-danger"><i className="fa fa-circle-exclamation" aria-hidden="true" /> {GetFeedbackMessageFor(errorMessage, errorKeyPrefix + "address.geocode")}</p> : null}
    </>
  );
}

export default AddressForm;