import { Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import "../../css/forms/StackedJobSearchForm.css";
import AutocompleteLocationInput from './AutocompleteLocationInput';

function StackedJobSearchForm() {
  const [cookies, setCookie] = useCookies(["job_search"]);
  const initialFormData = { what_search_text: "" };
  const [formData, setFormData] = useState({ ...initialFormData, ...cookies.job_search });
  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    setCookie("job_search", JSON.stringify(formData), { path: "/" });
    navigate('/jobs');
  };

  return (
    <Form className="stacked-search-jobs-form" onSubmit={e => handleSubmit(e)}>
      <Row className="mb-5">
        <Col>
          <FloatingLabel controlId="what-job-search" label={["What? ", <span key="what-job-search" className="floating-label-sm">(e.g. Chef, Engineer, Teacher) </span>]} className="mb-4">
            <Form.Control type="text" placeholder="What? (e.g. Chef, Engineer, Teacher)" value={formData.what_search_text} onChange={e => setFormData({ ...formData, what_search_text: e.target.value })} />
            <i className="inline-icon icon-btn p-2 fa fa-search" onClick={e => handleSubmit(e)} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <AutocompleteLocationInput {...{ formData, setFormData, handleSubmit }} />
        </Col>
      </Row>
      <Row>
        <Button type="submit" className="d-none standard-btn full-btn">Search</Button>
      </Row>
    </Form>
  );
}

export default StackedJobSearchForm;