import { Button, Row, Col } from 'react-bootstrap';

function MwHireInquiryStep({...props}) {
  function handleClick(isMwHire) {
    props.updateResolution({resolutionEvent: 'respond_to_mw_hire_inquiry', formData: {is_mw_hire: isMwHire}});
  }

  return (
    <div className="text-center">
      <Row>
        <Col className="mt-2" xs={12}>
          <p>Did you find a hire through Magnetworks?</p>
        </Col>
        <Col md={6} className="mt-3 mb-3">
          <Button onClick={() => handleClick(true)} className="standard-btn full-btn">Yes</Button>
        </Col>
        <Col md={6} className="mt-3 mb-3">
          <Button onClick={() => handleClick(false)} className="standard-btn full-btn">No</Button>
        </Col>
      </Row>
    </div>
  );
}

export default MwHireInquiryStep;