import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import JobsFiltersForModal from '../jobs/JobsFiltersForModal.js'
import { closeModal } from '../../actions/modalActions';

function JobsFiltersModal({ ...props }) {
  const dispatch = useDispatch();

  function hide() {
    dispatch(closeModal());
  };

  return (
    <div>
      <Modal show={true} onHide={e => hide()} fullscreen="md-down">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <JobsFiltersForModal hideModal={hide} {...props} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default JobsFiltersModal;