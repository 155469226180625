import { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';

import { GetFeedbackMessageFor } from '../../utils/FormUtils';

const RegionForm = ({ region }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    setFormData({
      ...formData,
      name: region?.name || ''
    });
  }, [region]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    const request = region?.id ?
      axios.put(`/api/v1/regions/${region.id}`, { region: formData }) :
      axios.post('/api/v1/regions', { region: formData });

    request
      .then(() => {
        navigate('/admin/regions');
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-1 mt-3 mb-4" controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              isInvalid={errors['name']}
              autoFocus
            />
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'name')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button style={{ width: '100px' }} type="submit" className="standard-btn" disabled={isSubmitting}>
            {isSubmitting ? <Spinner size="sm" /> : 'Save'}
          </Button>
          <Button style={{ width: '100px' }} type="button" as={Link} to={'/admin/regions'} className="ms-2 light-btn">
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );

}

export default RegionForm;