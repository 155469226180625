
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';

import { GetFeedbackMessageFor } from '../../utils/FormUtils';

const RewardSourceForm = ({ rewardSource }) => {
  const [regions, setRegions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    approved: false
  });

  const navigate = useNavigate();

  useEffect(() => {
    setFormData({
      ...formData,
      name: rewardSource?.name || '',
      region_id: rewardSource?.region?.id || '',
      approved: rewardSource?.approved || false
    });

    axios.get('/api/v1/regions')
      .then((response) => {
        setRegions(response.data);
      });
  }, [rewardSource]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    const request = rewardSource?.id ?
      axios.put(`/api/v1/reward_sources/${rewardSource.id}`, formData) :
      axios.post(`/api/v1/reward_sources`, formData);

    request
      .then((response) => {
        navigate('/admin/reward_sources');
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-1 mt-3" controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              name="reward_source[name]"
              placeholder="Enter name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              isInvalid={errors['name']}
            />
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'name')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Group className="mb-1 mt-3" controlId="region_id">
            <Form.Label>Region</Form.Label>
            <Form.Select
              name="reward_source[region_id]"
              value={formData.region_id}
              onChange={(e) => setFormData({ ...formData, region_id: e.target.value })}
              isInvalid={errors['region_id']}
            >
              <option value="">Select region</option>
              {regions.map((region) => (
                <option key={region.id} value={region.id}>{region.name}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'region_id')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Form.Group className="mt-3 mb-4" controlId="approved">
          <Form.Check
            type="checkbox"
            name="reward_source[approved]"
            label="Approved"
            className="standard-checkbox"
            checked={formData.approved}
            onChange={(e) => setFormData({ ...formData, approved: e.target.checked })}
          />
        </Form.Group>
      </Row>

      <Row>
        <Col>
          <Button style={{ width: '100px' }} type="submit" className="standard-btn" disabled={isSubmitting}>
            {isSubmitting ? <Spinner size="sm" /> : 'Save'}
          </Button>
          <Button style={{ width: '100px' }} type="button" as={Link} to={'/admin/reward_sources'} className="ms-2 light-btn">
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default RewardSourceForm;