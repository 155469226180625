import {OverlayTrigger, Tooltip} from "react-bootstrap";

function ConditionalTooltip({condition, children, message, placement}) {

  return (
    <>
      {condition ?
        <OverlayTrigger placement={placement || "top"} overlay={<Tooltip>{message}</Tooltip>}>
          {children}
        </OverlayTrigger>
        :
        <>
          {children}
        </>
      }
    </>
  );
}

export default ConditionalTooltip;
