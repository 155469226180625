import axios from 'axios';
import { Alert, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { signoutUser } from '../../actions/authActions';
import { openModal } from '../../actions/modalActions';
import { GetFeedbackMessageFor } from "../../utils/FormUtils";

function ResetPasswordForm({user, headers, ...props}) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({password: '', password_confirmation: ''});

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  };

  function toggleShowPasswordConfirmation() {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  function handleSubmit(event) {
    event.preventDefault();
    setShowPassword(false);
    setShowPasswordConfirmation(false);

    axios.post(`/api/v1/users/${user.id}/reset_password`, {user: formData}, {headers: headers})
    .then((response) => {
      dispatch(signoutUser());
      setErrorMessage('');
      setSuccessMessage(response.data.result);
    }, (error) => {
      setErrorMessage(error.response.data.error || 'An unknown error has occurred.');
    });
  };

  function handleLoginClick() {
    dispatch(openModal('LoginModal', {}));
  }

  return (
    <>
      <div className="text-center mb-2">
        <h5>Reset password</h5>
        <p>{user.email}</p>
      </div>

      {typeof errorMessage !== "object" && errorMessage && (
        <Alert variant="danger">{errorMessage}</Alert>
      )}

      {successMessage
      ?
        <div className="text-center">
          <Alert className="mb-3" variant="success">{successMessage}</Alert>
          <Link to="#" onClick={handleLoginClick}>Login</Link>
        </div>
      :
        <Form onSubmit={e => handleSubmit(e)}>
          <Row>
            <Col className="col-12 mb-3">
              <small className="text-muted">Use 8 or more characters with a mix of letters & numbers</small>
              <FloatingLabel controlId="password" label="Password">
                <Form.Control autoComplete="new-password" className="password-input" type={showPassword ? "text" : "password"} placeholder="Password" value={formData.password} onChange={e => setFormData({...formData, password: e.target.value})} isInvalid={errorMessage.password}/>
                <i className={showPassword ? "inline-icon icon-btn p-2 fa fa-eye-slash" : "inline-icon icon-btn p-2 fa fa-eye"} onClick={toggleShowPassword}/>
                <Form.Control.Feedback type="invalid">
                  {GetFeedbackMessageFor(errorMessage, "password")}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col className="col-12 mb-3">
              <FloatingLabel controlId="password-confirmation" label="Re-enter your password">
                <Form.Control autoComplete="new-password" className="password-input" type={showPasswordConfirmation ? "text" : "password"} placeholder="Re-enter your password" value={formData.password_confirmation} onChange={e => setFormData({...formData, password_confirmation: e.target.value})} isInvalid={errorMessage.password_confirmation}/>
                <i className={showPasswordConfirmation ? "inline-icon icon-btn p-2 fa fa-eye-slash" : "inline-icon icon-btn p-2 fa fa-eye"} onClick={toggleShowPasswordConfirmation}/>
                <Form.Control.Feedback type="invalid">
                  {GetFeedbackMessageFor(errorMessage, "password_confirmation")}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col className="col-12">
              <Button type="submit" className="standard-btn full-btn">Reset password</Button>
            </Col>
          </Row>
        </Form>
      }
    </>
  );
}

export default ResetPasswordForm;