import axios from 'axios';
import { Button, Row, Col } from 'react-bootstrap';

function InitiateResolutionStep({...props}) {
  function handleSubmit(event) {
    event.preventDefault();

    axios.post(`/api/v1//businesses/${props.businessId}/jobs/${props.jobId}/resolutions`).then(
      (response) => {
        props.setResolution(response.data);
      },
      (error) => {
        props.addDangerAlert(error.response.data.error);
        props.closeWizard();
      }
    );
  }

  return (
    <div className="text-center">
      <Row>
        <Col xs={12}>
          <p>Are you ready to close this job posting?</p>
        </Col>
        <Col md={6} className="mt-3 mb-3">
          <Button onClick={handleSubmit} className="standard-btn full-btn">Yes</Button>
        </Col>
        <Col md={6} className="mt-3 mb-3">
          <Button onClick={props.closeWizard} className="standard-btn full-btn">No</Button>
        </Col>
      </Row>
    </div>
  );
}

export default InitiateResolutionStep;