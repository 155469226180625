const initialState = {
  tokenChecked: false,
  loggedIn: false,
  currentUser: null,
  error: null
};

export default function authorization(state = initialState, action) {
  switch (action.type) {
    case 'AUTHENTICATED':
      return {
        ...state,
        tokenChecked: true,
        loggedIn: true,
        currentUser: action.payload,
      };
    case 'NOT_AUTHENTICATED':
      return {
        ...state,
        tokenChecked: true,
        loggedIn: false,
        currentUser: null,
        error: action.payload
      };
    case 'SET_AUTH_ERROR':
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}