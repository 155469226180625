import axios from 'axios';
import { Alert, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { openModal } from '../../actions/modalActions';

function ForgotPasswordForm() {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authData);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({email: ''});

  function handleSubmit(event) {
    event.preventDefault();
    setErrorMessage('');

    axios.post('/api/v1/users/forgot_password', formData)
    .then((response) => {
      let successMessage = response.data.result;
      setSuccessMessage(successMessage);
    }, (error) => {
      setErrorMessage(error.response.data.error || 'An unknown error has occurred.');
    });
  };

  function handleLoginClick() {
    dispatch(openModal('LoginModal', {}));
  }

  return (
    <>
      <div className="text-center">
        <h5>Forgot Your Password?</h5>
        <p>Enter your account's email address and we will send you a password reset link.</p>
      </div>

      {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}

      {successMessage
      ?
        <div className="text-center">
          <Alert className="mb-3" variant="success">{successMessage}</Alert>
          {!authData.loggedIn ? <Link to="#" onClick={handleLoginClick}>Login</Link> : null }
        </div>
      :
        <Form onSubmit={e => handleSubmit(e)}>
          <Row>
            <Col xs={12}>
              <FloatingLabel controlId="email" label="Email" className="mb-3">
                <Form.Control type="text" placeholder="Email" value={formData.email} onChange={e => setFormData({...formData, email: e.target.value})}/>
              </FloatingLabel>
            </Col>
            <Col xs={12} className="mb-3">
              <Button type="submit" className="standard-btn full-btn">Send password reset link</Button>
            </Col>
            {!authData.loggedIn
            ?
              <Col xs={12} className="text-center">
                <Link to="#" onClick={handleLoginClick}>Log in</Link>
              </Col>
            :
              null
            }
          </Row>
        </Form>
      }
    </>
  );
}

export default ForgotPasswordForm;