import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const ViewAffiliateLink = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`/api/v1/affiliate_links/${params.key}/view`)
      .then((response) => {
        navigate(`/signup?al=${response.data.id}${response.data.sign_up_type === 'employer' ? '&role=employer' : ''}`);
      })
      .catch((error) => {
        navigate('/');
      });
  }, [params]);

  return null;
}

export default ViewAffiliateLink;