import { Button, Row, Col } from 'react-bootstrap';

function HireInquiryStep({...props}) {
  function handleClick(isPositionFilled) {
    props.updateResolution({resolutionEvent: 'respond_to_hire_inquiry', formData: {is_position_filled: isPositionFilled}});
  }

  return (
    <div className="text-center">
      <Row>
        <Col className="mt-2" xs={12}>
          <p>Did you fill the opening?</p>
        </Col>
        <Col md={6} className="mt-3 mb-3">
          <Button onClick={() => handleClick(true)} className="standard-btn full-btn">Yes</Button>
        </Col>
        <Col md={6} className="mt-3 mb-3">
          <Button onClick={() => handleClick(false)} className="standard-btn full-btn">No</Button>
        </Col>
      </Row>
    </div>
  );
}

export default HireInquiryStep;