import { Button, Col, Row } from 'react-bootstrap';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import orangeMagnetPath from '../images/orange-magnet.png';
import chatBubblePath from '../images/chat-bubble.png';
import mapMarkerPath from '../images/map-marker.png';
import bullseyePath from '../images/bullseye.png';
import { useXLargeMediaQuery } from '../hooks/responsiveMediaQueries';
import { openModal } from '../actions/modalActions';

function HowItWorks() {
  const isWithinXLarge = useXLargeMediaQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useSelector((state) => state.authData);

  function handleStartHereClick() {
    if(authData.loggedIn) {
      navigate('/jobs');
    } else {
      dispatch(openModal('SignUpModal'));
    }
  }

  return (
    <>
      <Row className="g-0 how-it-works-container">
        <Col md={{ span: 7, order: 1 }} xs={{ span: 12, order: 1 }} className={"ps-5 pe-5 pe-xl-0 light-background " + (isWithinXLarge ? "" : "left-half-abstract-magnet")}>

          <Row className="mt-4 pt-4">
            <Col xs={12} className="text-center text-md-start pb-2">
              <h1 className="display-5 fw-bold">Referrers & <span className="primary-mw-text">Job Seekers</span></h1>
            </Col>
          </Row>

          <Row>
            <Col xxl={8} xl={10} lg={12}>
              <Row className="mb-2 mb-xxl-3">
                <Col xs={12}>
                  <p className="lead fw-normal">
                    We've made it easy for businesses, friends, and their communities to <span className="fw-bold tertiary-mw-text">share jobs via word of mouth</span>. The job search is better with friends.
                  </p>
                </Col>
              </Row>

              <Row className="mb-4 mb-xxl-3">
                <Col className="d-flex justify-content-center flex-column align-items-center pb-2 pb-md-0" sm={12} md={3}><img alt="Map marker" className="map-marker-img" src={mapMarkerPath} /></Col>
                <Col className="d-flex justify-content-center flex-column align-items-center text-center text-md-start" sm={12} md={9}>
                  <span className="lead fw-normal">
                    <span className="lead fw-bold">Explore new job opportunities</span><br />
                    Stay connected to the latest openings and help others by sharing roles within your network.
                  </span>
                </Col>
              </Row>

              <Row className="mb-4 mb-xxl-3">
                <Col className="d-flex justify-content-center flex-column align-items-center pb-2 pb-md-0" sm={12} md={3}><img alt="Chat bubble with heart" className="chat-bubble-img" src={chatBubblePath} /></Col>
                <Col className="d-flex justify-content-center flex-column align-items-center text-center text-md-start" sm={12} md={9}>
                  <span className="lead fw-normal">
                    <span className="lead fw-bold">Share and refer</span><br />
                    Spread the word about job opportunities, and when someone you refer gets hired, you can earn local rewards.
                  </span>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="d-flex justify-content-center flex-column align-items-center pb-2 pb-md-0" sm={12} md={3}><img alt="Target with arrow" className="bullseye-img" src={bullseyePath} /></Col>
                <Col className="d-flex justify-content-center flex-column align-items-center text-center text-md-start" sm={12} md={9}>
                  <span className="lead fw-normal">
                    <span className="lead fw-bold">Help your community</span><br />
                    By sharing jobs and referring friends, you’re playing a part in helping your community grow and succeed.
                  </span>
                </Col>
              </Row>
            </Col>

          </Row>
        </Col>
        <Col md={{ span: 5, order: 2 }} xs={{ span: 12, order: 2 }} className="d-flex flex-column vh-40-container vh-84-md-container right-half-abstract-magnet dark-background align-items-center justify-content-center">
          <Button className="px-1 light-btn cta-btn fw-bold mb-4" onClick={handleStartHereClick}>Start Here</Button>
          <Col xs={12} className="py-4 text-center">
            <h1 className="display-3 fw-bold" style={{color: 'white'}}>Better <br/>Jobs, <br/>Found <br/>Together</h1>
          </Col>
          <Col xs={12} className="text-center">
            <img alt="magnetworks" className="orange-magnet-img" src={orangeMagnetPath} />
          </Col>
        </Col>
      </Row>
    </>
  );
}

export default HowItWorks;