import { Card, Placeholder } from 'react-bootstrap';
import "../../css/Jobs.css";

function PlaceholderJobCard() {
  return (
    <Card className="job-card mb-2">
      <Card.Body>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder bg="dark" xs={6} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder bg="secondary" xs={10} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder bg="secondary" xs={4} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder bg="secondary" xs={4} />
        </Placeholder>

      </Card.Body>
      <Card.Footer>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder bg="secondary" xs={6} />
        </Placeholder>
      </Card.Footer>
    </Card>
  );
}

export default PlaceholderJobCard;