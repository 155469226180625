import { Container } from 'react-bootstrap';
import '../css/Loading.css';

function Loading() {
  return (
    <Container>
      <div className="loading-container d-flex justify-content-center align-items-center">
        <div className="text-center">
          <i className="fa fa-spinner fa-2x fa-spin"/>
          <p className="mt-2">Loading ...</p>
        </div>
      </div>
    </Container>
  );
}

export default Loading;