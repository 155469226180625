import { Form } from 'react-bootstrap';
import React from "react";

export const FileUploadInput = React.forwardRef(({ errorMessage = "", hintText = "", ...props }, ref) => (
  <>
    <Form.Control ref={ref} type="file" className="hidden" onChange={props.onChange} {...props} />
    <Form.Control.Feedback type="invalid">
      {errorMessage}
    </Form.Control.Feedback>
    {hintText && <small className="text-muted">{hintText}</small>}
  </>
));