import axios from "axios";

export const FetchFile = async ({ url, fileName, type }) => {
  try {
    const response = await axios.get(url, { responseType: 'arraybuffer' })
    const file = new File([response.data], fileName, {type: type})

    return {valid: true, fetchFile: file}
  }
  catch(error) {
    return { valid: false, error: error }
  }
}
