import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { useMobileMediaQuery } from "../hooks/responsiveMediaQueries";

import Loading from "./Loading.js"
import ViewJob from "./ViewJob.js"

import axios from "axios";

import "../css/ShowJob.css";

function ShowJob({ businessId }) {
  const navigate = useNavigate();

  const isMobile = useMobileMediaQuery();

  const [searchParams, setSearchParams] = useSearchParams();
  const frame = searchParams.get('frame');

  const { job_id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [job, setJob] = useState();
  const authData = useSelector((state) => state.authData);

  useEffect(() => {
    if (job === undefined || (job !== undefined && authData.loggedIn)) {
      fetchJob(job_id);
    }
  }, [job_id, authData]);

  function fetchJob(job_id) {
    setisLoading(true);

    axios.get(`/api/v1/jobs/${job_id}`)
    .then(
      (response) => {
        setJob(response.data);
        setisLoading(false);
      },
      (error) => {
        navigate("/not_found", { replace: true });
      }
    );
  }

  return(
    <>
    {isLoading ?
      <Loading></Loading>
    :
      <Container>
        <Row>
          <Col className="col-12 mt-3">
            {!(isMobile && frame !== null) &&
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: businessId ? `/employers/${businessId}/jobs?job_id=${job.id}` : `/jobs?job_id=${job.id}` }}>jobs</Breadcrumb.Item>
                <Breadcrumb.Item active>{job.title}</Breadcrumb.Item>
              </Breadcrumb>
            }
          </Col>
        </Row>
        <ViewJob job={job} businessId={businessId}></ViewJob>
      </Container>
    }
    </>
  );
}

export default ShowJob;