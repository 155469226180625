import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Container, Breadcrumb, Col, Row, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import DownloadFileLink from "./DownloadFileLink";
import { GetFeedbackMessageFor } from "../utils/FormUtils";
import { addGlobalAlert } from "../actions/globalAlertActions";

import Loading from "./Loading.js";
import PhoneInput from "../components/inputs/PhoneInput.js";
import useGetApi from '../hooks/useApiGet';

function InstantApplyDefaults() {
  const dispatch = useDispatch();
  const resumeFileHiddenInput = useRef(null);
  const coverLetterFileHiddenInput = useRef(null);

  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const {data, loading} = useGetApi({url: '/api/v1/me/default_application'});
  const [applicationDefaults, setApplicationDefaults] = useState([]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: ""
  });

  useEffect(() => {
    if (!loading) {
      if(data) {
        setApplicationDefaults(data);
        setIsLoading(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!isEditing) {
      setFormData(applicationDefaults);
      setResume(null);
      setCoverLetter(null);
      setErrorMessage("");
      setErrors([]);
    }
  }, [isEditing]);

  useEffect(() => {
    setFormData(applicationDefaults);
  }, [applicationDefaults]);

  function refetchDefaultApplication() {
    axios.get(`/api/v1/me/default_application`).then(
      (response) => {
        setApplicationDefaults(response.data);
      },
      (error) => { }
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    setErrors([]);
    setErrorMessage("");

    const finalFormData = new FormData(event.target);
    if(resume) finalFormData.append("default_application[resume]", resume);
    if(coverLetter) finalFormData.append("default_application[cover_letter]", coverLetter);

    for (const k in formData) {
      finalFormData.append(`default_application[${k}]`, formData[k] ? formData[k] : "");
    }
    const request_type = applicationDefaults?.id ? axios.put(`/api/v1/me/default_application`, finalFormData) : axios.post(`/api/v1/me/default_application`, finalFormData);

    request_type.then(
      (response) => {
        let submittedAlertMessage = applicationDefaults?.id ? "Successfully updated Instant Apply defaults." : "Successfully created Instant Apply defaults."
        setIsSubmitting(false);
        dispatch(addGlobalAlert({message: submittedAlertMessage, alertType: 'success', removeOnPageChange: false}));
        refetchDefaultApplication();
        setIsEditing(false);
        resumeFileHiddenInput.current.value = '';
        coverLetterFileHiddenInput.current.value = '';
      },
      (error) => {
        window.scrollTo(0, 0);
        setErrors(error.response.data.error)
        setErrorMessage("Instant Apply defaults have failed to update.")
        setIsSubmitting(false);
      }
    );
    setIsSubmitting(false);
  };

  return (
    <>
    {isLoading ?
      <Loading/>
    :
      <Container>
        {!isLoading && (formData !== null) ?
          <>
            <Row className="pt-3 pb-3">
              <Col xs={9} >
                <Breadcrumb>
                  <Breadcrumb.Item active>Instant Apply Defaults</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col xs={3} className="text-end text-md-center">
                  {isEditing ?
                    <i className="icon-as-link text-danger fa-regular fa-2x fa-circle-xmark mx-1" onClick={() => setIsEditing(false)}></i>
                    :
                    <span className="icon-as-link fa-stack fa-1x pb-3" onClick={() => setIsEditing(true)}>
                      <i className="fas fa-stack-2x fa-circle"></i>
                      <i className="fas fa-stack-1x fa-pencil" style={{ color: "#FFFFFF" }}></i>
                    </span>
                  }
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              </Col>
            </Row>
            <Row>
              <Col lg={7} md={12} sm={12}>
                <Form onSubmit={!isSubmitting ? (e) => handleSubmit(e) : null}>
                  <Row className="mb-3">
                    <Form.Group controlId="first-name" as={Col} sm={12} md={6}>
                      <Form.Label className="">First name</Form.Label>
                      <Form.Control type="text" value={formData?.first_name ?? ""} isInvalid={errors.first_name} disabled={!isEditing} onChange={e => setFormData({...formData, first_name: e.target.value})}/>
                      <Form.Control.Feedback type="invalid">
                        {GetFeedbackMessageFor(errors, "first_name")}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="last-name" as={Col} sm={12} md={6} className="mt-md-0 mt-3">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control type="text" value={formData?.last_name ?? ""} isInvalid={errors.last_name} disabled={!isEditing} onChange={e => setFormData({...formData, last_name: e.target.value})}/>
                      <Form.Control.Feedback type="invalid">
                        {GetFeedbackMessageFor(errors, "last_name")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Form.Group controlId="email" className="mb-3">
                    <Form.Label className="">Email</Form.Label>
                    <Form.Control type="email" value={formData?.email ?? ""}  isInvalid={errors.email} disabled={!isEditing} onChange={e => setFormData({...formData, email: e.target.value})}/>
                    <Form.Control.Feedback type="invalid">
                      {GetFeedbackMessageFor(errors, "email")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="phone" className="mb-3">
                    <Form.Label className="">Phone</Form.Label>
                    <PhoneInput value={formData?.phone ?? ""} isInvalid={errors.phone} disabled={!isEditing} onChange={e => setFormData({ ...formData, phone: e.target.value.replace(/\D/g, '') })} />
                    <Form.Control.Feedback type="invalid">
                      {GetFeedbackMessageFor(errors, "phone")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="resume" className="mb-3">
                    <Form.Label className="">Resume</Form.Label>
                    {formData?.resume_url ? <span className="text-center ms-2"><DownloadFileLink fileUrl={formData?.resume_url} fileName={formData?.resume_filename}></DownloadFileLink></span> : ""}
                    <InputGroup className="mb-3 input-file-hover" onClick={() => resumeFileHiddenInput.current.click()}>
                      <InputGroup.Text>
                        Choose file
                      </InputGroup.Text>
                      <input type="file" className="d-none" disabled={!isEditing} ref={resumeFileHiddenInput} onChange={e => setResume(e.target.files[0])} />
                      <Form.Control type="text" disabled={!isEditing} readOnly={!isEditing ? true : false} isInvalid={errors.resume} className="file-name-field" value={!isEditing ? applicationDefaults?.resume_filename || "No file chosen" : (resume?.name || formData?.resume_filename || "No file chosen")} />
                      <Form.Control.Feedback type="invalid">
                        {GetFeedbackMessageFor(errors, "resume")}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="cover_leter" className="mb-3">
                    <Form.Label className="">Cover letter</Form.Label>
                    {formData?.cover_letter_url ? <span className="text-center ms-2"><DownloadFileLink fileUrl={formData?.cover_letter_url} fileName={formData?.cover_letter_filename}></DownloadFileLink></span> : ""}
                    <InputGroup className="mb-3 input-file-hover" onClick={() => coverLetterFileHiddenInput.current.click()}>
                      <InputGroup.Text>
                        Choose file
                      </InputGroup.Text>
                      <input type="file" className="d-none" disabled={!isEditing} ref={coverLetterFileHiddenInput} onChange={e => setCoverLetter(e.target.files[0])} />
                      <Form.Control type="text" disabled={!isEditing} readOnly={!isEditing ? true : false} isInvalid={errors.cover_letter} className="file-name-field" value={!isEditing ? applicationDefaults?.cover_letter_filename || "No file chosen" : (coverLetter?.name || formData?.cover_letter_filename || "No file chosen")} />
                      <Form.Control.Feedback type="invalid">
                        {GetFeedbackMessageFor(errors, "cover_letter")}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  {isEditing ?
                    <Row>
                      <Col md={3} className="mt-3">
                        <Button className="standard-btn w-100" type="submit" disabled={isSubmitting}>
                          {isSubmitting ?
                            <i className="fa fa-spinner fa-spin"/>
                          :
                            "Save"
                          }
                        </Button>
                      </Col>
                    </Row>
                  : null
                  }
                </Form>
              </Col>
            </Row>
          </>
        :
          <Row className="justify-content-center">
            <Col xs={8} md={4} className="mt-4">
              <Alert className="text-center" variant={'secondary'}>
                <div>We are sorry.</div>
                <div>Instant Apply defaults do not exist.</div>
              </Alert>
            </Col>
          </Row>
        }
      </Container>
    }
    </>
  );
}

export default InstantApplyDefaults;
