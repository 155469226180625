import { Container } from 'react-bootstrap';
import ForgotPasswordForm from './forms/ForgotPasswordForm.js'

function ForgotPassword() {
  return (
    <Container className="mt-5 form-container">
      <ForgotPasswordForm/>
    </Container>
  );
}

export default ForgotPassword;