const initialState = {
  modalType: null,
  modalProps: {}
}

export default function modal(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    case 'CLOSE_MODAL':
      return initialState;
    default:
      return state;
  }
}