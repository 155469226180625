
import axios from 'axios';
import { Button, Row, Col, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function ApplicantSelectionStep({...props}) {
  const [applicants, setApplicants] = useState([]);
  const [loadingApplicants, setLoadingApplicants] = useState(true);
  const [selectedApplicants, setSelectedApplicants] = useState(props.resolution.selected_applicants);
  const [errors, setErrors] = useState('');

  useEffect(() => {
    loadingApplicants && fetchApplicants();
  }, [applicants]);

  function fetchApplicants() {
    axios.get(`/api/v1//businesses/${props.businessId}/jobs/${props.jobId}/applicants`).then(
      (response) => {
        setApplicants(response.data.applicants);
      },
      () => {
        setApplicants([]);
      }
      );
    setLoadingApplicants(false);
  }

  function handleClick({foundApplicant}) {
    let applicationId = foundApplicant ? selectedApplicants[0]?.application_id : null;
    props.updateResolution({ resolutionEvent: 'respond_to_applicant_selection', formData: { found_applicant: foundApplicant, application_id: applicationId }, handleValidationError: handleValidationError})
  }

  function handleValidationError(errorMessage) {
    setErrors(errorMessage);
  }

  return (
    <>
      { loadingApplicants
        ?
        <Row>
          <Col className="m-2" xs={12}>
            <div className="text-center">
              <p>Fetching applicants...</p>
              <i className="fa fa-spinner fa-2x fa-spin"/>
            </div>
          </Col>
        </Row>
        :
        <div>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Who was hired?</Form.Label>
                <Typeahead className={errors.applicant_selection ? "is-invalid" : ""} isInvalid={errors.applicant_selection !== undefined} id="applicant-select" selected={selectedApplicants} labelKey="display_value" onChange={(selectedApplicants) => setSelectedApplicants(selectedApplicants)} options={applicants} placeholder="Choose an applicant..." />
                <Form.Control.Feedback type="invalid">{errors.applicant_selection}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="mt-4" xs={12}>
              <Form.Group>
                <Form.Label>Did you find your applicant?</Form.Label>
                <Row>
                  <Col md={6} className="mt-2 mb-3">
                    <Button onClick={() => handleClick({ foundApplicant: true })} className="standard-btn full-btn">Yes</Button>
                  </Col>
                  <Col md={6} className="mt-2 mb-3">
                    <Button onClick={() => handleClick({ foundApplicant: false })} className="standard-btn full-btn">No</Button>
                  </Col>
                </Row>
              </Form.Group>
            </Col>

          </Row>
        </div>
      }
    </>
  );
}

export default ApplicantSelectionStep;