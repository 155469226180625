function MinSalaryOptions() {
  return (
    <>
    <option value="" hidden>Min Salary</option>
    <option data-slug="$40,000+" value="40000">$40,000+</option>
    <option data-slug="$60,000+" value="60000">$60,000+</option>
    <option data-slug="$80,000+" value="80000">$80,000+</option>
    <option data-slug="$100,000+" value="100000">$100,000+</option>
    <option data-slug="$120,000+" value="120000">$120,000+</option>
    <option data-slug="$140,000+" value="140000">$140,000+</option>
    <option data-slug="$160,000+" value="160000">$160,000+</option>
    <option data-slug="$180,000+" value="180000">$180,000+</option>
    <option data-slug="$200,000+" value="200000">$200,000+</option>
    </>
  )
}

export default MinSalaryOptions;