import { Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { removeGlobalAlert } from '../actions/globalAlertActions';

function GlobalAlert() {
  const dispatch = useDispatch();
  const location = useLocation();
  let {message, alertType, removeOnPageChange} = useSelector((state) => state.globalAlertData);

  useEffect(() => {
    if (removeOnPageChange) {
      dispatch(removeGlobalAlert());
    }
  }, [location]);

  function removeAlert() {
    dispatch(removeGlobalAlert());
  };

  return (
    <>
      {message ? <Alert className="text-center mx-2 mt-2" variant={alertType} onClose={removeAlert} dismissible>{message}</Alert> : null}
    </>
  );
}

export default GlobalAlert;