import { Modal } from 'react-bootstrap';
import { closeModal } from '../../actions/modalActions';
import { useDispatch } from 'react-redux';
import ResolveJobWizard from '../employers/resolve_job_wizard/ResolveJobWizard.js'

function EmployersResolveJobModal({...props}) {
  const dispatch = useDispatch();

  function closeWizard(event) {
    props.fetchJobs();
    dispatch(closeModal());
  }

  return (
    <div>
      <Modal size={"lg"} show={true} onHide={e => closeWizard(e)} fullscreen="md-down">
        <Modal.Header className="px-4" closeButton>
          <div className="ms-auto">Closing <strong>{props.jobTitle}</strong> Position</div>
        </Modal.Header>
        <Modal.Body>
          <ResolveJobWizard closeWizard={e => closeWizard(e)} {...props}/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default EmployersResolveJobModal;