import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';

function PhoneInput(props) {
  return (
    <Form.Control
      as={InputMask}
      type="text"
      mask="(999)-999-9999"
      alwaysShowMask={props.alwaysShowMask}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      {...props} >
    </Form.Control>
  );
}

export default PhoneInput;