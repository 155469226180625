import { useEffect, useState } from "react";
import axios from "axios";

const useApiGet = ({url, payload = {}}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(url, { params: payload })
      .then((response) => {setData(response.data);})
      .catch((error) => {setError(error);})
      .finally(() => setLoading(false));
  }, []);

  return { data, error, loading };
};

export default useApiGet;