import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import DefaultBannerPath from '../../../images/default-banner.png';
import '../../../css/employers/EmployersPublicProfileHeader.css';

function EmployersPublicProfileHeader({ business }) {
  const [userBelongsToBusiness, setUserBelongsToBusiness] = useState(false);

  useEffect(() => {
    axios.get(`/api/v1/businesses/${business.id}`).then(
      (response) => {
        if (response.data.id === business.id) {
          setUserBelongsToBusiness(true);
        } else {
          setUserBelongsToBusiness(false);
        }
      },
      (error) => {
        setUserBelongsToBusiness(false);
      }
    );
  }, []);

  function getDynamicBannerStyle() {
    const backgroundImage = business.banner ? business.banner : DefaultBannerPath;
    return {backgroundImage: "url(" + backgroundImage + ")"};
  }

  return (
    <Container className="employer-profile-header">
      <Row className="justify-content-center">
        <Col xs={12} lg={10} xl={9} xxl={8} className="d-flex g-0 mt-md-2">
          <div style={getDynamicBannerStyle()} className="banner"></div>
        </Col>
        <Col xs={12} lg={10} xl={9} xxl={8}>
          <Row>
            <Col xs={6}>
              {business.logo &&
                <div className="ms-3 logo-container">
                  <img className="logo" src={business.logo} alt="business-logo" />
                </div>
              }
            </Col>
            <Col xs={6}>
              {userBelongsToBusiness && <div className="mt-1 text-end"><Link to="/employers/my_business/profile">Edit job feed</Link></div>}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="mt-2 mx-3">
                <h5>{business.name}</h5>
                {business.tagline && <div>{business.tagline}</div>}
                <span className="small">{business.employer_size.name} employees</span>
                {business.address && <span className="small"> - {business.address.city}, {business.address.state.code}</span>}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default EmployersPublicProfileHeader;