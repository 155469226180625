import { Modal } from 'react-bootstrap';
import { closeModal } from '../../actions/modalActions';
import { useDispatch } from 'react-redux';
import ForgotPasswordForm from '../forms/ForgotPasswordForm.js'

function ForgotPasswordModal({...props}) {
  const dispatch = useDispatch();

  function handleHide(event) {
    dispatch(closeModal());
  }

  return (
    <div>
      <Modal show={true} onHide={e => handleHide(e)} fullscreen="md-down">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ForgotPasswordForm/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ForgotPasswordModal;