import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { addGlobalAlert } from '../actions/globalAlertActions';
import { openModal } from '../actions/modalActions';
import { can } from '../services/ability';
import { useState, useEffect } from 'react';

const ProtectedRoute = ({action, subject, children, redirectPath = '/'}) => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authData);
  const [outlet, setOutlet] = useState(<></>);

  useEffect(() => {
    if (can(authData, action, subject)) {
      setOutlet(children);
    }
    else if (!authData.tokenChecked) {
      setOutlet(<></>)
    }
    else {
      !authData.loggedIn ? dispatch(openModal('LoginModal', {})) : dispatch(addGlobalAlert({message: 'You do not have permission to view this page.', alertType: 'danger', removeOnPageChange: false}));
      setOutlet(<Navigate to={redirectPath}/>)
    }
  }, [authData, action, subject, children, redirectPath, dispatch]);

  return outlet;
};

export default ProtectedRoute;