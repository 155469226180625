import axios from 'axios';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { addGlobalAlert } from '../actions/globalAlertActions';
import Loading from './Loading.js'
import ResetPasswordForm from './forms/ResetPasswordForm.js'

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParams = useLocation().search;
  const userId = new URLSearchParams(queryParams).get('id');
  const token = new URLSearchParams(queryParams).get('token');

  const [user, setUser] = useState('');
  const [tokenVerified, setTokenVerified] = useState(false);
  const [headers] = useState({'X-reset-password-token': token || ''});

  React.useEffect(() => {
    if(!tokenVerified)
    {
      axios.get(`/api/v1/users/${userId}/verify_reset_password_token`, {headers})
      .then((response) => {
        setTokenVerified(true);
        setUser(response.data);
      }, (error) => {
        let errorMessage = '';

        if(error.response.status === 401 || error.response.status === 404) {
          errorMessage = 'Oops! This reset password link has either expired or is invalid. Please try again.'
        }
        else {
          errorMessage = 'An unknown error has occurred. Please try again.'
        }

        setTokenVerified(false);
        dispatch(addGlobalAlert({message: errorMessage, alertType: 'danger', removeOnPageChange: false}));
        navigate('/forgot_password');
      });
    }
  }, [tokenVerified, headers, dispatch, navigate]);

  return (
    <>
      {!tokenVerified ? <Loading/> : <Container className="mt-5 form-container"><ResetPasswordForm headers={headers} user={user}/></Container>}
    </>
  );
}

export default ResetPassword;