import { apiClient } from "./apiClient";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export function createApplication({jobId, data, requestOptions = {}}) {
  let uri = `/api/v1/jobs/${jobId}/applications`;
  if (cookies.get(`sl-jid-${jobId}`))
    uri += `?key=${cookies.get(`sl-jid-${jobId}`)}`;

  return apiClient.post(uri, data, requestOptions);
}