import { Row, Col, Breadcrumb, Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import Loading from "../Loading";
import PhoneInput from "../inputs/PhoneInput";
import useGetApi from "../../hooks/useApiGet";
import { GetFeedbackMessageFor } from "../../utils/FormUtils";
import { addGlobalAlert } from "../../actions/globalAlertActions";
import { can } from "../../services/ability";

function UserSettingsForm() {
  const { data, loading } = useGetApi({ url: "/api/v1/users/me" });
  const paymentMethodData = useGetApi({ url: "/api/v1/payment_methods" });
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authData);
  const [userData, setUserData] = useState({});
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (!loading && !paymentMethodData.loading && data) {
      setUserData(data);
      setIsLoading(false);
    }
  }, [loading, paymentMethodData.loading, data]);

  useEffect(() => {
    setupForm();
  }, [userData]);

  function setupForm() {
    setFormData({
      first_name: userData.first_name || '',
      last_name: userData.last_name || '',
      email: userData.email || '',
      phone: userData.phone || '',
      referral_payment_method_id: userData.referral_payment_method_id || ''
    })
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    setErrors([]);

    axios.patch("/api/v1/users/me", formData)
      .then(response => {
        setIsEditing(false);
        refecthUserData();
        dispatch(addGlobalAlert({ message: "Settings successfully updated.", alertType: "success", removeOnPageChange: true }));
      })
      .catch(error => {
        window.scrollTo(0, 0);
        setErrors(error.response.data.error);
        dispatch(addGlobalAlert({ message: "Please correct the errors below.", alertType: "danger", removeOnPageChange: true }));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function refecthUserData() {
    axios.get(`/api/v1/users/me`).then(
      (response) => {
        setUserData(response.data);
      },
      (error) => {}
    );
  }

  function handleCancelClick() {
    setIsEditing(false);
    setupForm();
  }

  return (
    <>
      {isLoading ?
        <Loading />
      :
        <>
          <Row className="pt-3 pb-3">
            <Col xs={6} md={4} >
              <Breadcrumb>
                <Breadcrumb.Item active>Manage user settings</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xs={6} md={4} className="text-end">
              {isEditing ?
                <i className="icon-as-link text-danger fa-regular fa-2x fa-circle-xmark mx-1" onClick={() => handleCancelClick()}></i>
                :
                <span className="icon-as-link fa-stack fa-1x pb-3" onClick={() => setIsEditing(true)}>
                  <i className="fas fa-stack-2x fa-circle"></i>
                  <i className="fas fa-stack-1x fa-pencil" style={{ color: "#FFFFFF" }}></i>
                </span>
              }
            </Col>
          </Row>
          <Row>
            <Col md={8} >
              <Form onSubmit={!isSubmitting ? (e) => handleSubmit(e) : null} >
                <Row>
                  <Form.Group as={Col} md={12} lg={6}>
                    <Form.Label>First name</Form.Label>
                    <Form.Control type="text" isInvalid={errors.first_name} value={formData.first_name} onChange={(e) => setFormData({ ...formData, first_name: e.target.value })} disabled={!isEditing} />
                    <Form.Control.Feedback type="invalid">
                      {GetFeedbackMessageFor(errors, "first_name")}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={12} lg={6}>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control type="text" isInvalid={errors.last_name} value={formData.last_name} onChange={(e) => setFormData({ ...formData, last_name: e.target.value })} disabled={!isEditing} />
                    <Form.Control.Feedback type="invalid">
                      {GetFeedbackMessageFor(errors, "last_name")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Col}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" isInvalid={errors.email} value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} disabled={!isEditing} />
                    <Form.Control.Feedback type="invalid">
                      {GetFeedbackMessageFor(errors, "email")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Col}>
                    <Form.Label>Phone</Form.Label>
                    <PhoneInput isInvalid={errors.phone} value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} disabled={!isEditing} />
                    <Form.Control.Feedback type="invalid">
                      {GetFeedbackMessageFor(errors, "phone")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                {can(authData, 'manage', 'ReferralPaymentMethod') ?
                  <Row className="mt-3">
                    <Form.Group as={Col} lg={6}>
                      <Form.Label>Referral payment method</Form.Label>
                      <Form.Select isInvalid={errors.referral_payment_method} value={formData.referral_payment_method_id} onChange={(e) => setFormData({ ...formData, referral_payment_method_id: e.target.value })} disabled={!isEditing}>
                        {paymentMethodData.data.map(paymentMethod => {
                          return <option key={paymentMethod.id} value={paymentMethod.id}>{paymentMethod.name}</option>
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {GetFeedbackMessageFor(errors, "referral_payment_method")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                :
                  null
                }
                {isEditing ?
                  <Row className="mt-4">
                    <Col>
                      <Button id="update-settings-btn" className="standard-btn" type="submit" disabled={isSubmitting}>
                        {isSubmitting ?
                          <i className="fa fa-spinner fa-spin"/>
                        :
                          "Update settings"
                        }
                      </Button>
                    </Col>
                  </Row>
                :
                  null
                }
              </Form>
            </Col>
          </Row>
        </>
      }
    </>
  );
}

export default UserSettingsForm;