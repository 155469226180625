import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { can } from '../../services/ability';

const AffiliateLinkTableEntry = ({ affiliateLink }) => {
  const authData = useSelector((state) => state.authData);

  return (
    <tr>
      <td>{affiliateLink.id}</td>
      <td>{affiliateLink.name}</td>
      <td>{affiliateLink.key}</td>
      <td className="text-end">
        {can(authData, 'read', 'affiliate_link') &&
          <Button variant="secondary" as={Link} to={`/admin/affiliate_links/${affiliateLink.id}/details`} className="me-1">
            <i className="fa fa-info-circle" />
          </Button>
        }
        {can(authData, 'update', 'affiliate_link') &&
          <Button variant="secondary" as={Link} to={`/admin/affiliate_links/${affiliateLink.id}/edit`}>
            <i className="fa fa-edit" />
          </Button>
        }
      </td>
    </tr>
  );
}

export default AffiliateLinkTableEntry;