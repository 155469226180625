import { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { GetFeedbackMessageFor } from '../../utils/FormUtils';
import axios from 'axios';

const AffiliateLinkForm = ({ affiliateLink }) => {
  const [formData, setFormData] = useState({ name: '', key: '', message: '', sign_up_type: ''});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setFormData({
      ...formData,
      name: affiliateLink?.name || '',
      key: affiliateLink?.key || '',
      message: affiliateLink?.message || '',
      sign_up_type: affiliateLink?.sign_up_type || 'all'
    });
  }, [affiliateLink]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    const request = affiliateLink?.id ?
      axios.put(`/api/v1/affiliate_links/${affiliateLink.id}`, formData) : axios.post(`/api/v1/affiliate_links`, formData);

    request
      .then((response) => {
        navigate('/admin/affiliate_links');
      })
      .catch((error) => {
        setErrors(error.response.data.error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-1 mt-3" controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              isInvalid={errors['name']}
            />
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'name')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Group className="mb-3 mt-3" controlId="key">
            <Form.Label className="required">Key</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter key"
              value={formData.key}
              onChange={(e) => setFormData({ ...formData, key: e.target.value })}
              isInvalid={errors['key']}
            />
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'key')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Group className="mb-3 mt-3" controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              isInvalid={errors['message']}
            />
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'message')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Group className="mb-3 mt-3" controlId="sign_up_type">
            <Form.Label className="required">Sign up type</Form.Label>
            <Form.Select
              value={formData.sign_up_type}
              onChange={(e) => setFormData({ ...formData, sign_up_type: e.target.value })}
              isInvalid={errors['sign_up_type']}
            >
              <option value='all'>All</option>
              <option value='candidate'>Candidates</option>
              <option value='employer'>Employers</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'sign_up_type')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className='mt-3'>
          <Button style={{width: '100px'}} type="submit" className="standard-btn" disabled={isSubmitting}>Save</Button>
          <Button style={{ width: '100px'}} type="button" as={Link} to={'/admin/affiliate_links'} className="ms-2 light-btn">Cancel</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default AffiliateLinkForm;