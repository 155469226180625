import { serialize_html, serialize_text } from "../services/rich_text_editor/SerializerService"
import { deserialize } from "../services/rich_text_editor/DeserializerService"
import parse from 'html-react-parser'

export const deserialize_slate = html => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return deserialize(doc.body);
}

export const serialize_slate = (nodes, type = 'text') => {
  if(type === 'html') {
    return parse(nodes.map((node) => serialize_html(node)).join(""));
  }
  else {
    return serialize_text(nodes);
  }
}
