
import { rewardIcon, rewardLabel } from '../../utils/JobUtils';

const JobRewardSummary = ({ reward }) => {
  return (
    <span className="fw-bold mw-text-small d-flex align-items-center">
      {rewardIcon(reward, 'pe-1 fa-lg text-success')}
      Reward - {rewardLabel(reward)}
    </span>
  );
}

export default JobRewardSummary;