import { Row, Col, Form, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import useApiGet from '../../../hooks/useApiGet';

function BillingConfirmationStep({...props}) {
  const [loading, setLoading] = useState(false);
  const costBreakdown = useApiGet({ url: `/api/v1/businesses/${props.businessId}/jobs/${props.jobId}/resolutions/${props.resolution.id}/cost_for_mw_hires_breakdown` }).data || {};
  const job = useApiGet({ url: `/api/v1/businesses/${props.businessId}/jobs/${props.jobId}` }).data || {};

  function confirmationMessage() {
    if(Object.keys(job).length === 0) return <></>;

    if(job.reward?.type == 'Reward::Cash') {
      return (
        <>
          <div className="px-5">By clicking confirm you agree to pay {costBreakdown.human_friendly_cost_for_mw_hires} for this hire through Magnetworks.</div>
          <small>Charge will appear on your next invoice.</small>
        </>
      );
    }
    else if(job.reward?.code) {
      return (
        <>
          <div className="px-5">By clicking confirm you agree to reward the referrer.</div>
          <small>We will deliver the promotion code to the referrer.</small>
        </>
      );
    }
    else {
      return (
        <>
          <div className="px-5">By clicking confirm you agree to reward the referrer.</div>
          <small>We will contact you for details.</small>
        </>
      );
    }
  }

  function handleClick({ resolutionEvent , showLoading }) {
    showLoading && setLoading(true);
    props.updateResolution({ resolutionEvent: resolutionEvent });
  }

  return (
    <>
      { loading
      ?
        <Row>
          <Col className="m-2" xs={12}>
            <div className="text-center">
              <p>Please do not leave or refresh this page...</p>
              <i className="fa fa-spinner fa-2x fa-spin"/>
            </div>
          </Col>
        </Row>
      :
        <div className="text-center">
          <Row>
            { props.resolution.found_applicant
            ?
              <Col xs={12} className="mb-4">
                <div>Applicant found <i className="fa-solid fa-check text-success"></i></div>
                <small>Fantastic! Everyone gets paid for a job well done.</small>
              </Col>
            :
              <Col xs={12} className="mb-4">
                <div>Applicant not found <i className="fa-solid fa-xmark text-danger"></i></div>
                <small>No worries, we will track them down!</small>
              </Col>
            }
          </Row>
          <Row>
            <Col xs={12}>
              <Col xs={12}>
                {confirmationMessage()}
              </Col>
              <Col className="mt-4" xs={12}>
                <Form.Group>
                  <Row>
                    <Col md={6} className="mt-3 mb-3">
                      <Button onClick={() => handleClick({ resolutionEvent: 'go_back_to_applicant_selection', showLoading: false })} className="standard-btn full-btn">Back</Button>
                    </Col>
                    <Col md={6} className="mt-3 mb-3">
                      <Button onClick={() => handleClick({ resolutionEvent: 'confirm_billing', showLoading: true })} className="standard-btn full-btn">Confirm</Button>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Col>
          </Row>
        </div>
      }
    </>
  );
}

export default BillingConfirmationStep;