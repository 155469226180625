import { Button, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '../../../css/NotFound.css';

function EmployerNotFound() {
  const navigate = useNavigate();

  return (
    <Container className="not-found-container text-center justify-content-center align-items-center">
      <Row className="rounded-2 justify-content-center">
        <Col className="pt-4 pb-4">
          <Row>
            <Col>
              <h1 className="status-code-text display-1">404</h1>
              <h5>OOPS! PAGE NOT FOUND</h5>
              <p>The employer you are looking for does not exist.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="standard-btn m-1" onClick={() => navigate('/jobs')}>Jobs</Button>
              <Button className="standard-btn m-1" onClick={() => navigate(-1)}>Go Back</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default EmployerNotFound;