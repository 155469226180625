import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { verifyToken } from './actions/authActions';
import { CookiesProvider } from 'react-cookie';
import axios from 'axios';

import './index.css';
import store from './store';
import App from './App';

if(process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = process.env.REACT_APP_API_HOST || 'http://localhost:3001';
  axios.defaults.withCredentials = true;
}

store.dispatch(verifyToken());

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
