import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from './../actions/authActions';
import { Link } from 'react-router-dom';

import SignUpForm from './forms/SignupForm';
import { openModal } from '../actions/modalActions';

function SignUpFormWrapper({ ...props }) {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authData);

  function clearErrors() {
    if (authData.error) {
      dispatch(clearError());
    }
  }

  return (
    <>
      <div className="text-center">
        <h5>Welcome to Magnetworks</h5>
        {props.affiliateLink && <p className="text-muted">{props.affiliateLink?.message}</p>}
        <p>Sign up</p>
      </div>
      <Tabs className="mb-3" justify defaultActiveKey={props.roleName || "Hunter"} mountOnEnter={true} onSelect={clearErrors}>
        <Tab eventKey="Hunter" title="Candidate/Referrer">
          <SignUpForm {...props} roleName="Hunter" />
        </Tab>
        <Tab eventKey="Employer" title="Employer">
          <SignUpForm {...props} roleName="Employer" />
        </Tab>
      </Tabs>
      <div className="text-center mt-3">
        <p className="mb-2">Already have an account? <Link to="#" onClick={() => dispatch(openModal('LoginModal'))}>Log in</Link></p>
        <small className="text-muted">By creating an account you agree to our <Link to="/terms" target="_blank">Terms of Use</Link>.</small>
      </div>
    </>
  );
}

export default SignUpFormWrapper;