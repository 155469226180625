import React from "react";
import axios from "axios";

function DownloadFileLink(props) {
  function downloadFile() {
    axios({url: props.fileUrl, method: 'GET', responseType: "blob"})
      .then(response => {
        const href = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', props.fileName);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(error => {alert(props.errorMessage)});
  };

  return (
    <>
      {props.fileUrl.length === 0 ? props.notAvailableMessage : <a href="#" onClick={downloadFile}>{props.linkText ?? <i className="fas fa-file-download"></i>}</a>}
    </>
  );
}

DownloadFileLink.defaultProps = {
  errorMessage: "There was an error downloading the file.",
  notAvailableMessage: "N/A"
};

export default DownloadFileLink;