import escapeHtml from 'escape-html';
import { Text, Node } from 'slate';

export const serialize_text = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};

export const serialize_html = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);

    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    return string;
  }

  const children = node.children.map((n) => serialize_html(n)).join("");

  const textAlignStyle = `text-align: ${node.align}`
  switch (node.type) {
    case 'bulleted-list':
      return `<ul style="${textAlignStyle}">${children}</ul>`;
    case 'heading-one':
      return `<h3 style="${textAlignStyle}">${children}</h3>`;
    case 'heading-two':
      return `<h4 style="${textAlignStyle}">${children}</h4>`;
    case 'list-item':
      return `<li style="${textAlignStyle}">${children}</li>`;
    case 'numbered-list':
      return `<ol style="${textAlignStyle}">${children}</ol>`;
    default:
      return `<p style="${textAlignStyle}">${children}</p>`;
  }
};