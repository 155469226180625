import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addGlobalAlert } from "../../../actions/globalAlertActions";
import { ApplicantSelectionStep, CompletionConfirmationStep, HireInquiryStep, InitiateResolutionStep, MwHireInquiryStep, BillingConfirmationStep } from './WizardSteps';
import StepProgressBar from './StepProgressBar';

function ResolveJobWizard({...props}) {
  const dispatch = useDispatch();
  const [loadingResolution, setLoadingResolution] = useState(true);
  const [resolution, setResolution] = useState('');
  const [wizardCompontent, setWizardComponent] = useState('');
  const stepPositionsMap = {
    initial: 14,
    hire_inquiry: 32,
    mw_hire_inquiry: 50,
    applicant_selection: 68,
    billing_confirmation: 86
  }
  const [percentComplete, setPercentComplete] = useState(stepPositionsMap.initial - 1);

  useEffect(() => {
    if(loadingResolution) {
      fetchbResolution();
    }
    else {
      setContent();
    }
  }, [resolution, loadingResolution]);

  function fetchbResolution() {
    axios.get(`/api/v1//businesses/${props.businessId}/jobs/${props.jobId}/resolution`).then(
      (response) => {
        setResolution(response.data);
      },
      () => {
        setResolution('');
      }
    );

    setLoadingResolution(false);
  }

  function updateResolution({resolutionEvent, formData, handleValidationError, setLoading}) {
    axios.put(`/api/v1/businesses/${props.businessId}/jobs/${props.jobId}/resolutions/${resolution.id}`, {event: resolutionEvent, resolution: formData}).then(
      (response) => {
        setResolution(response.data);
      },
      (error) => {
        let errorMessage = error.response.data.error;

        // When the error is an object, it is a rails validation error hash and should be handled by each step.
        // If handleValidationError is not defined, we do not want to call it, so there is potential here that the error message will get lost.
        // It cannot be passed to addDangerAlert because it is not equipped to handle an object.
        if(typeof errorMessage == "object") {
          if(handleValidationError) {
            handleValidationError(errorMessage);
          }
        }
        else {
          addDangerAlert(errorMessage);
          props.closeWizard();
        }
      }
    );
  }

  function setContent() {
    switch(resolution.status) {
      case 'hire_inquiry':
        setPercentComplete(stepPositionsMap.hire_inquiry - 1);
        setWizardComponent(<HireInquiryStep updateResolution={updateResolution} {...props} />);
        break;
      case 'mw_hire_inquiry':
        setPercentComplete(stepPositionsMap.mw_hire_inquiry - 1);
        setWizardComponent(<MwHireInquiryStep updateResolution={updateResolution} {...props} />);
        break;
      case 'applicant_selection':
        setPercentComplete(stepPositionsMap.applicant_selection - 1);
        setWizardComponent(<ApplicantSelectionStep updateResolution={updateResolution} resolution={resolution} {...props} />);
        break;
      case 'billing_confirmation':
        setPercentComplete(stepPositionsMap.billing_confirmation - 1);
        setWizardComponent(<BillingConfirmationStep updateResolution={updateResolution} resolution={resolution} {...props} />);
        break;
      case 'complete':
        setPercentComplete(100);
        setWizardComponent(<CompletionConfirmationStep resolution={resolution} {...props} />);
        break;
      default:
        setWizardComponent(<InitiateResolutionStep addDangerAlert={addDangerAlert} setResolution={setResolution} {...props}/>)
    }
  }

  function addDangerAlert(message) {
    dispatch(addGlobalAlert({message: message, alertType: "danger"}));
  }

  return (
     <>
      <div className="mb-4">
        <StepProgressBar percentComplete={percentComplete} stepPositions={Object.values(stepPositionsMap)}/>
      </div>
      {wizardCompontent ? wizardCompontent : null}
     </>
  );
}

export default ResolveJobWizard;