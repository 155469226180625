import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";

function StepProgressBar({...props}) {
  function progressStep(step) {
    return (
      <Step key={"step-"+step} transition="scale">
        {({ accomplished }) => (
          accomplished ?
            <span className="fa-stack fa-1x secondary-mw-text">
              <i className="fas fa-stack-2x fa-circle"></i>
              <i className="fas fa-stack-1x fa-check" style={{color: "#FFFFFF"}}></i>
            </span>
          :
            <span className="fa-stack fa-1x text-secondary">
              <i className="fas fa-stack-2x fa-circle"></i>
              <i className={"fas fa-stack-1x fa-" + step} style={{color: "#FFFFFF"}}></i>
            </span>
        )}
      </Step>
    );
  }

  return (
    <ProgressBar percent={props.percentComplete} filledBackground="#2EB2BF" stepPositions={props.stepPositions}>
      {props.stepPositions.map((element, index) => (
        progressStep(index + 1)
      ))}
    </ProgressBar>
  );
}

export default StepProgressBar;