import { Row, Col, Breadcrumb, Form, Button, InputGroup } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import { addGlobalAlert } from "../../actions/globalAlertActions";
import { GetFeedbackMessageFor } from "../../utils/FormUtils";

function UpdatePasswordForm() {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const oldPasswordRef = useRef();
  const dispatch = useDispatch();

  function reset() {
    setFormData({ old_password: "", password: "" });
    setErrors([]);
    setShowOldPassword(false);
    setShowNewPassword(false);
  }

  useEffect(() => {
    if (isEditing) {
      oldPasswordRef.current.focus();
    }
    else {
      reset();
    }
  }, [isEditing]);

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    setErrors([]);

    axios.patch("/api/v1/users/update_password", formData)
      .then(response => {
        dispatch(addGlobalAlert({ message: "Password changed successfully.", alertType: "success", removeOnPageChange: true }));
        setIsEditing(false);
        setFormData({ old_password: "", password: "" });
      })
      .catch(error => {
        window.scrollTo(0, 0);
        setErrors(error.response.data.error);
        dispatch(addGlobalAlert({ message: "Please correct the errors below.", alertType: "danger", removeOnPageChange: true }));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <>
      <Row className="pt-3 pb-3">
        <Col xs={6} md={4} >
          <Breadcrumb>
            <Breadcrumb.Item active>Change password</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs={6} md={4} className="text-end">
          {isEditing ?
            <i className="icon-as-link text-danger fa-regular fa-2x fa-circle-xmark mx-1" onClick={() => setIsEditing(false)}></i>
            :
            <span className="icon-as-link fa-stack fa-1x pb-3" onClick={() => setIsEditing(true)}>
              <i className="fas fa-stack-2x fa-circle"></i>
              <i className="fas fa-stack-1x fa-pencil" style={{ color: "#FFFFFF" }}></i>
            </span>
          }
        </Col>
      </Row>
      {isEditing ?
        <>
          <Row>
            <Col md={8} >
              <Form onSubmit={!isSubmitting ? (e) => handleSubmit(e) : null} >
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>Old password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control type={showOldPassword ? "text" : "password"} ref={oldPasswordRef} isInvalid={errors.old_password} value={formData.old_password} onChange={(e) => {setErrors({ ...errors, old_password: "" }); setFormData({ ...formData, old_password: e.target.value })}} />
                      <Button variant="outline-secondary" onClick={() => setShowOldPassword((prev) => !prev) }>
                        <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                      </Button>
                      <Form.Control.Feedback type="invalid">
                        {GetFeedbackMessageFor(errors, "old_password")}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Col}>
                    <Form.Label>New password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control type={showNewPassword ? "text" : "password"} isInvalid={errors.password} value={formData.password} onChange={(e) => {setErrors({ ...errors, password: "" }); setFormData({ ...formData, password: e.target.value })}} />
                      <Button variant="outline-secondary" onClick={() => setShowNewPassword((prev) => !prev) }>
                        <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                      </Button>
                      <Form.Control.Feedback type="invalid">
                        {GetFeedbackMessageFor(errors, "password")}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Button id="change-password-btn" className="standard-btn" type="submit" disabled={isSubmitting}>
                      {isSubmitting ?
                        <i className="fa fa-spinner fa-spin"/>
                      :
                        "Change password"
                      }
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      :
        null
      }
    </>
  );
}

export default UpdatePasswordForm;