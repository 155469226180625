import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SignUpFormWrapper from './SignUpFormWrapper';
import { can } from '../services/ability';
import axios from 'axios';

function SignUp() {
  const navigate = useNavigate();
  const [searchParamsChecked, setSearchParamsChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [roleName, setRoleName] = useState(null);
  const [affiliateLink, setAffiliateLink] = useState(null);
  const authData = useSelector((state) => state.authData);

  useEffect(() => {
    if (authData.loggedIn) {
      window.scrollTo(0, 0);

      if (can(authData, 'read', 'employers_dashboard')) {
        navigate('/employers/dashboard');
      } else {
        navigate('/jobs')
      }
    }
  }, [authData]);

  useEffect(() => {
    if (searchParams.has('role')) {
      let searchParamRole = searchParams.get('role').toLocaleLowerCase()

      if (searchParamRole.match('employer')) {
        setRoleName('Employer')
      }
    }

    if (searchParams.has('al')) {
      fetchAffiliateLink(searchParams.get('al'));
    }

    setSearchParamsChecked(true);
  }, [searchParamsChecked]);

  function fetchAffiliateLink(affiliateLinkId) {
    axios.get(`/api/v1/affiliate_links/${affiliateLinkId}/view?no_event=true`)
      .then((response) => {
        setAffiliateLink(response.data);
      })
      .catch((error) => {
        // Do nothing for now. The only impact of not finding the affiliate link is that the user will not see a custom message.
      });
  }

  return (
    <Container className="mt-5 form-container">
      {searchParamsChecked ? <SignUpFormWrapper roleName={roleName} affiliateLink={affiliateLink} isModal={false} /> : null}
    </Container>
  );
}

export default SignUp;