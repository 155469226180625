import { Container, Tabs, Tab } from "react-bootstrap";

import UserSettingsForm from "./forms/UserSettingsForm";
import UpdatePasswordForm from "./forms/UpdatePasswordForm";

import "../css/UserSettings.css";

function UserSettings() {

  return (
    <Container>
      <Tabs defaultActiveKey="settings" className="mt-3">
        <Tab eventKey="settings" title="User settings">
          <UserSettingsForm />
        </Tab>
        <Tab eventKey="password" title="Change password">
          <UpdatePasswordForm />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default UserSettings;