import React from 'react';

function Privacy() {
  return (
    <>
      <div className="container px-4 mt-4 mb-1">
        <div className="mb-4 text-center">
          <div><strong>Magnetworks Privacy Policy</strong></div>
          <div><strong>Version 1.0</strong></div>
          <div><strong>Last revised on: March 14, 2024</strong></div>
        </div>
        <div>
          <p>The website located at www.magnetworks.com ("Site") is a copyrighted work belonging to Magnet Works, Inc. Throughout this Privacy Policy, the terms "we", "us" and "our" refer to Magnet Works, Inc. We offer this Site, including all information, tools and services available from this Site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated herein.</p>
          <p>By visiting our Site and/or purchasing something from us, you engage in our "Service" and agree to be bound by the following Privacy Policy including those additional terms and conditions and policies referenced herein and/or available by hyperlink. This Privacy Policy applies to all users of the Site.</p>
          <p>Please read this Privacy Policy carefully before accessing or using our Site. By accessing or using any part of the Site, you agree to be bound by this Privacy Policy. If you do not agree to all the terms and conditions of this agreement, then you may not access the Site or use any services.&nbsp;</p>
          <p>This Privacy Policy is subject to change, you can review the most current version at any time on this page. We reserve the right to update, change or replace any part of this Privacy Policy by posting updates and/or changes to our Site. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Site following the posting of any changes constitutes acceptance of those changes.</p>
          <p>If you reside in California, you may have additional data rights. Please refer to the "California Privacy Rights" section below.</p>

          <p><strong>1. Data Collection</strong></p>
          <p>We collect certain information about you when you visit our Site. This information may personally identify you or have the potential to personally identify you ("Personal Information"). This section describes the Personal Information we collect and the sources of that information.</p>
          <p><strong>Information We Collect</strong></p>
          <ul>
            <li>Identifiers: We may collect your full name, email address, physical address, phone number, IP Address, device ID, third-party account information or other similar identifiers. We may also collect any other Personal Information that you provide to us, such as social media identifier or language preference.</li>
            <li>Internet or Other Electronic Network Activity Information: We may collect information regarding your interaction with the Services, including time of visit and geolocation data, pages visited, links clicked, emails opened, language preferences, and the pages that led or referred you to our Services. We may also collect information automatically from your device, such as device type, operating system name and version, device manufacturer and model, language, plug-ins, and add-ons.</li>
            <li>Geolocation Data: We may collect your physical location or infer your physical location based on your IP address, mailing address, or other network or voluntarily provided information.</li>
            <li>Commercial Information: We may collect information about the products or services you have purchased, obtained, or considered from us.</li>
            <li>Payment Information: In order to process your order, we collect credit card information or other payment information you provide to us.</li>
            <li>Professional or Employment-related Information: We may collect job-related information from you, such as your title, and work experience. You provide this Personal Information in your account profile, through your voluntary communications with us, by providing contact or payment information (such as your email address or company credit card), or by responding to an email, newsletter, or survey.</li>
          </ul>
          <p>You may refuse to provide, or request that we delete, certain categories of Personal Information we have about you. However, if our collection of the Personal Information is required by law or contract or is necessary for you to access or use the Services, we may be unable to provide the Services to you.</p>
          <p><strong>Data Sources</strong></p>
          <ul>
            <li>We may automatically collect certain information about the mobile device, computer or other devices that you use to access the Services through commonly used information-gathering tools, such as cookies and web beacons (see our Cookies section below).</li>
            <li>You provide some Personal Information to us directly. For example, you provide Identifiers, such as name, email address, phone number, and billing address, and Payment Information for us to process your order.</li>
            <li>We may generate information about you based on our relationship with you or your use of the Services. Such information may be aggregated or anonymized before we process it.</li>
            <li>Third parties or public sources may provide us with certain Personal Information. For example, we may collect Personal Information from publicly accessible social media profiles and posts, private third parties, law enforcement, or public sources.</li>
          </ul>

          <p><strong>2. Data usage</strong></p>
          <p>This section describes the purposes for which we process your Personal Information. We process your Personal Information:</p>
          <ul>
            <li>To perform our contractual obligations to you. This includes but is not limited to providing you access to the Services and, specifically, the Site; processing customer service requests; sending you</li>
          </ul>
          <p>order confirmations and updates; processing payment for your orders; performing our contractual obligations to you under our Terms of Use; and providing you with access to the products and Services you purchase from us.</p>
          <ul>
            <li>For legitimate business interests which outweigh the general privacy rights of individual data subjects. This includes but is not limited to analyzing and improving our Services and business operations; ensuring the security and availability of the Services; and monitoring use of the Services to prevent fraud or abuse.</li>
            <li>To comply with a legal obligation or requirement. We use and process your Personal Information when we believe doing so is necessary to comply with laws and regulations, pursuant to a judicial authorization, or to exercise or defend our legal rights or those of a third party.</li>
            <li>With your consent. We process your Personal Information if we obtained your affirmative consent to such processing, such as to send you our newsletter and other marketing communications that you requested, or to respond to inquiries and questions that you initiated.</li>
          </ul>
          <p>If we need to process your Personal Information for a purpose other than that for which the information was initially collected, we will provide you with information about that other purpose before we further process your Personal Information.</p>

          <p><strong>3. Data Sharing and Disclosure</strong></p>
          <p>We may share your Personal Information with third parties:</p>
          <ul>
            <li>With your consent. We share your Personal Information if you affirmatively consent to it. If we are legally obligated to obtain your consent prior to sharing your Personal Information, we notify you of the specific purpose for which we are obtaining your consent, and we do not share beyond that purpose without notifying you of and obtaining additional consent for further disclosure.</li>
          </ul>
          <ul>
            <li>With third party service providers and agents. We share Personal Information with a limited number of service providers who process it on our behalf to provide or improve our business functions, and who have agreed to privacy restrictions similar to the ones in our Privacy Policy by making similar commitments.</li>
            <li>For legal disclosure. We may share your information with third parties when we reasonably believe disclosure is required or permitted in order to comply with a subpoena, court order or other applicable law, regulation or legal process.</li>
            <li>To protect us or others. We may share your information to the extent we believe that sharing such information is necessary to protect the rights, property, or safety of us, our products or services, our customers, or others.</li>
            <li>As aggregate information. We may share certain aggregated, non-personally identifying information internally or with others for purposes of research, reporting, and benchmarking.</li>
            <li>As a result of merger or sale of our business. We may share Personal Information if we are involved in a merger, sale, financing, liquidation, bankruptcy or acquisition of corporate entities or business units.</li>
          </ul>
          <p>We do not sell any Personal Information for any reason.</p>

          <p><strong>4. Data Access and Control</strong></p>
          <p>If you allow consumers to control certain Personal Information through other methods, such as a user account or user profile, you should describe how the consumer can control information through those methods here.</p>

          <p><strong>5. Data Rentention</strong></p>
          <p>We retain your Personal Information according to applicable laws or regulatory requirements and keep it as long as is necessary to fulfill the purposes described in this Privacy Policy or for which the personal data was collected.</p>

          <p><strong>6. Data Security</strong></p>
          <p>While no online service is 100% secure, we work to protect information about you against unauthorized access, use, alteration, or destruction, and take reasonable measures to do so. We monitor our Services for potential vulnerabilities and attacks, and we use a variety of security technologies and organizational procedures to help protect your personal data from unauthorized access, use, or disclosure. We also</p>
          <p>limit access to your Personal Information to those personnel with a need to know in order to perform job duties for us.</p>

          <p><strong>7. Children's Privacy</strong></p>
          <p>We do not collect Personal Information about or from persons under the age of thirteen. If you are under the age of thirteen you CANNOT use the Site.</p>

          <p><strong>8. Third Party Links</strong></p>
          <p>We may provide content on the Services that links to third-party websites. For example, we may post links to our social media profiles so you can find us easily. We do not control, and are not responsible for, third parties' privacy practices and content. When you click on a link to a third-party site, the third party may collect your personal information, which will be governed by the third party's privacy policy. Please read the third party's privacy policy to learn about how they collect and process your personal information.</p>

          <p><strong>9. California Privacy Rights</strong></p>
          <p>This Section 9 applies only to individuals residing in the State of California who are considered "consumers" and from whom we collect "personal information" as those terms are defined in the California Consumer Privacy Act of 2018 (CCPA) and California Privacy Rights Act of 2023 (CPRA) . We will review and update this Section 9 at least once every twelve (12) months to reflect changes in our business, legal or regulatory obligations, so please check this Section 9 periodically for changes.</p>
          <p><em>How We Collect, Use, and Disclose Your Data.</em></p>
          <p>We collect the categories of Personal Information listed in the "Information We Collect" section above as well as Sensitive Personal Information. "Sensitive Personal Information" includes your, social security number, driver's license number, State ID number, passport number, account login, financial account number, debit and credit card numbers, your precise geolocation, your race, ethnic origin, religious and philosophical beliefs, union-membership status, contents of messages sent to and by you, your genetic data, your biometric information, heal information and sexual lifestyle and orientation. We use the information for the business or commercial purposes specified in the "Data Uses" section above. We disclose your information to the categories of third parties listed in the "Data Sharing and Disclosure" section above. We use cookies as described in our Cookies Policy.</p>
          <p><em>Your CCPA and CPRA Rights and Choices.</em></p>
          <p>California consumers have the following rights regarding our use and disclosure of your personal information, subject to certain limitations in the CCPA and CPRA:</p>
          <ul>
            <li>The right to know. You may request, up to twice in a 12-month period, the following information about the personal information we have collected about you during the past 12 months:</li>
            <ul>
              <li>the categories and specific pieces of personal information we have collected about you;</li>
              <li>the categories of sources from which we collected the personal information;</li>
              <li>the business or commercial purpose for which we collected the personal information;</li>
              <li>the third parties with whom we shared the personal information; and</li>
              <li>the categories of personal information about you that we disclosed for a business purpose, and the third parties to whom we disclosed that information for a business purpose.</li>
            </ul>
            <li>The right to delete. You may request that we delete the personal information we have collected about you, subject to certain limitations under applicable law.</li>
            <li>The right to correct. You may request that we correct the personal information we have collected about you, subject to certain limitations under applicable law.</li>
            <li>The right to opt-out from the sharing of your personal information. You may request to opt out of any communication of your personal information to another party.</li>
            <li>The right to non-discrimination. The CCPA provides that you may not be discriminated against for exercising these rights.</li>
            <li>The right to limit our use and disclosure of your Sensitive Personal Information. You may request that we limit or stop using and disclosing your Sensitive Personal Information except to the extent necessary to perform our services and provide our goods as would be reasonably expected by an average consumer who requests our goods or services or as otherwise permitted by law.</li>
          </ul>
          <p>You may exercise any of the rights above by submitting a request using the Contact Information provided below. We may need to verify your identity before responding to your request, such as by requesting government-issued identification or confirming your full name, email address or account access.</p>
          <p><em>How Long We Retain Your Data</em></p>
          <p>We will hold and use your personal information only as long as is reasonably necessary: (i) to provide the services or goods for which you provided the information, (ii) to exercise or fulfil any rights or obligations we have in contract or law, and (iii) to advertise our services or goods to you.</p>

          <p><strong>10. Cookies Policy</strong></p>
          <p>Cookies are small text files that are stored in a web browser's memory. Cookies help us remember your preferences and that you have already logged in, and they help us analyze how you use the Site so we can improve the Services. This Cookies Policy explains how we use cookies and other similar technologies to help us ensure that our Services function properly, prevent fraud and other harm, and analyze and improve the Services in accordance with our Privacy Policy. Any capitalized term used and not otherwise defined below has the meaning assigned to it in the Privacy Policy.</p>
          <p><em>How We Use Cookies</em></p>
          <p>Cookies help us personalize the Services and offer an effective and safe Site. We update our cookies periodically, but we generally use cookies for the following purposes:</p>
          <ul>
            <li>To operate the Services. We use cookies for functions such as authentication, fraud prevention and detection, and features and preferences.</li>
            <li>To analyze and improve the Services. We use cookies to understand how you use the Site and the Services so that we can improve your user experience.</li>
            <li>For better advertising. We use cookies to help us deliver more relevant ads to you.</li>
          </ul>
          <p><em>How You Can Manage Cookies</em></p>
          <p>Your web browser may allow you to control the cookies we and other websites set on your computer.</p>
          <p>Please consult the help section of your web browser for more information on how to delete cookies. However, note that if you choose to delete or disable cookies, we may be unable to provide the Site and the Services to you.</p>

          <p><strong>11. Contact Information</strong></p>
          <div className="mb-4">
            <div>Magnet Works, Inc.</div>
            <div>PO Box 254</div>
            <div>Stockbridge, MA 01262</div>
            <div>United States</div>
            <div>Phone: (+1) 413-200-8777</div>
            <div>compliance@magnetworks.co</div>
            <div><a target="_blank" href="mailto:compliance@magnetworks.co">compliance@magnetworks.co</a></div>
          </div>

          <p><strong>12. Changes to this Privacy Policy</strong></p>
          <p>We will provide you with notice of changes to the way we process your Personal Information and will obtain your consent as required by applicable law. All changes are effective immediately upon posting a notice of such changes. Your continued access to or use of the Services following the posting of changes constitutes your acknowledgment of such changes. You can see when this Privacy Policy was last updated by checking the date displayed at the top of this Privacy Policy.</p>
        </div>
      </div>
    </>
  );
}

export default Privacy;