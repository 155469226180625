import React from "react";
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import axios from "axios";

function ViewShareLink() {
  const thirtyDaysMilliseconds = 2_592_000_000;
  const navigate = useNavigate();
  const { key } = useParams();
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if(key) {
      fetchShareLink();
    }
  }, []);

  function fetchShareLink() {
    axios.get(`/api/v1/share_links/${key}`)
    .then(response => {
      if(response.data.job_id)
        setCookie(`sl-jid-${response.data.job_id}`, key, { path: '/', expires: new Date(Date.now() + thirtyDaysMilliseconds) });
    })
    .finally(() => {
      navigate(`/jobs/${key}`);
    });
  }

  return (
    <></>
  );
}

export default ViewShareLink;