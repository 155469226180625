const initialState = {
  message: null,
  alertType: null,
  removeOnPageChange: null
}

export default function globalAlert(state = initialState, action) {
  switch (action.type) {
    case 'ADD_GLOBAL_ALERT':
      return {
        message: action.message,
        alertType: action.alertType,
        removeOnPageChange: action.removeOnPageChange
      };
    case 'REMOVE_GLOBAL_ALERT':
      return initialState;
    default:
      return state;
  }
}