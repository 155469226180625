export const can = (authData, action, subject) => {
  try {
    if(authData.currentUser)
    {
      let _action = action.toLowerCase();
      const canPermissions = authData.currentUser.permissions["can"]
      let canManageAll = canPermissions.hasOwnProperty("manage") && canPermissions["manage"].hasOwnProperty("all")

      let hasSpecificPermission = false;
      if(canPermissions.hasOwnProperty(_action)) {
        if(Array.isArray(canPermissions[_action])) {
          hasSpecificPermission = canPermissions[_action].includes(subject);
        }
        else {
          hasSpecificPermission = canPermissions[_action].hasOwnProperty(subject);
        }
      }

      return canManageAll || hasSpecificPermission
    }
    else
    {
      return false
    }
  }
  catch(error) {
    return false
  }
};