import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RegionTableEntry = ({ region }) => {

  return (
    <tr>
      <td>{region.id}</td>
      <td>{region.name}</td>
      <td className="text-end">
        <Button variant="secondary" as={Link} to={`/admin/regions/${region.id}/edit`}>
          <i className="fa fa-edit" />
        </Button>
      </td>
    </tr>
  );
}

export default RegionTableEntry;