import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Row, Col, Form } from "react-bootstrap";
import MinSalaryOptions from "./MinSalaryOptions";

import "../../css/Jobs.css";
import useApiGet from '../../hooks/useApiGet';

function JobsFiltersForFullPage({ formDataContext, setApplyFilters }) {
  const formData = formDataContext.formData;
  const setFormData = formDataContext.setFormData;
  const [cookies, setCookie] = useCookies(["job_search"]);

  const jobTypes = useApiGet({ url: '/api/v1/job_types' }).data || [];
  const jobFunctions = useApiGet({ url: '/api/v1/job_functions' }).data || [];
  const jobIndustries = useApiGet({ url: '/api/v1/job_industries' }).data || [];
  const jobLocationTypes = useApiGet({ url: '/api/v1/job_location_types' }).data || [];
  const jobExperienceLevels = useApiGet({ url: '/api/v1/job_experience_levels' }).data || [];

  useEffect(() => {
    setCookie("job_search", JSON.stringify(formData));
    setApplyFilters(true);
  }, [
    formData.filters.job_type_id_eq,
    formData.filters.job_function_id_eq,
    formData.filters.job_industry_id_eq,
    formData.filters.job_location_type_id_eq,
    formData.filters.job_experience_level_id_eq,
    formData.filters.job_min_salary_eq
  ]);

  return (
    <>
      <Row className="align-items-center">
        <Col>
          {
            formData.filters.job_type_id_eq ?
              <div className="selected-filter">
                {formData.filters.job_type}
                <span className="cross-button"
                  onClick={(e) => {
                    setFormData({ ...formData, filters: { ...formData.filters, job_type_id_eq: '', job_type: '' } });
                  }
                  }
                >x</span>
              </div>
              :
              <Form.Select aria-label="Job Type"
                onChange={(e) => {
                  setFormData({ ...formData, filters: { ...formData.filters, job_type_id_eq: e.target.value, job_type: e.target.options[e.target.selectedIndex].dataset.slug } });
                }
                } >
                <option>Job Type</option>
                {jobTypes.map(jobType => <option data-slug={jobType.name} value={jobType.id} key={jobType.id}>{jobType.name}</option>)}
              </Form.Select>
          }
        </Col>
        <Col>
          {
            formData.filters.job_min_salary_eq ?
              <div className="selected-filter">
                {formData.filters.job_min_salary}
                <span className="cross-button"
                  onClick={ () => {
                    setFormData({ ...formData, filters: { ...formData.filters, job_min_salary_eq: "", job_min_salary: "" } })
                  }}
                >x</span>
              </div>
            :
              <Form.Select aria-label="Min Salary"
                onChange={(e) => {
                  setFormData({ ...formData, filters: { ...formData.filters, job_min_salary_eq: e.target.value, job_min_salary: e.target.options[e.target.selectedIndex].dataset.slug } });
                }}
              >
                <MinSalaryOptions />
              </Form.Select>
          }
        </Col>
        <Col>
          {
            formData.filters.job_experience_level_id_eq ?
              <div className="selected-filter">
                {formData.filters.job_experience_level}
                <span className="cross-button"
                  onClick={() => {
                    setFormData({ ...formData, filters: { ...formData.filters, job_experience_level_id_eq: '', job_experience_level: '' } })
                  }
                  }
                >x</span>
              </div>
              :
              <Form.Select aria-label="Experience Level"
                onChange={(e) => {
                  setFormData({ ...formData, filters: { ...formData.filters, job_experience_level_id_eq: e.target.value, job_experience_level: e.target.options[e.target.selectedIndex].dataset.slug } });
                }
                } >
                <option>Experience Level</option>
                {jobExperienceLevels.map(jobExperienceLevel => <option data-slug={jobExperienceLevel.name} value={jobExperienceLevel.id} key={jobExperienceLevel.id}>{jobExperienceLevel.name}</option>)}
              </Form.Select>
          }
        </Col>
        <Col>
          {
            formData.filters.job_location_type_id_eq ?
              <div className="selected-filter">
                {formData.filters.job_location_type}
                <span className="cross-button"
                  onClick={() => {
                    setFormData({ ...formData, filters: { ...formData.filters, job_location_type_id_eq: '', job_location_type: '' } })
                  }
                  }
                >x</span>
              </div>
              :
              <Form.Select aria-label="Location Type"
                onChange={(e) => {
                  setFormData({ ...formData, filters: { ...formData.filters, job_location_type_id_eq: e.target.value, job_location_type: e.target.options[e.target.selectedIndex].dataset.slug } });
                }
                } >
                <option>Location Type</option>
                {jobLocationTypes.map(jobLocationType => <option data-slug={jobLocationType.name} value={jobLocationType.id} key={jobLocationType.id}>{jobLocationType.name}</option>)}
              </Form.Select>
          }
        </Col>
        <Col>
          {
            formData.filters.job_industry_id_eq ?
              <div className="selected-filter">
                {formData.filters.job_industry}
                <span className="cross-button"
                  onClick={() => {
                    setFormData({ ...formData, filters: { ...formData.filters, job_industry_id_eq: '', job_industry: '' } })
                  }
                  }
                >x</span>
              </div>
              :
              <Form.Select aria-label="Industry"
                onChange={(e) => {
                  setFormData({ ...formData, filters: { ...formData.filters, job_industry_id_eq: e.target.value, job_industry: e.target.options[e.target.selectedIndex].dataset.slug } });
                }
                } >
                <option>Industry</option>
                {jobIndustries.map(jobIndustry => <option data-slug={jobIndustry.name} value={jobIndustry.id} key={jobIndustry.id}>{jobIndustry.name}</option>)}
              </Form.Select>
          }
        </Col>
        <Col>
          {
            formData.filters.job_function_id_eq ?
              <div className="selected-filter">
                {formData.filters.job_function}
                <span className="cross-button"
                  onClick={() => {
                    setFormData({ ...formData, filters: { ...formData.filters, job_function_id_eq: '', job_function: '' } })
                  }
                  }
                >x</span>
              </div>
              :
              <Form.Select aria-label="Function"
                onChange={(e) => {
                  setFormData({ ...formData, filters: { ...formData.filters, job_function_id_eq: e.target.value, job_function: e.target.options[e.target.selectedIndex].dataset.slug } });
                }
                } >
                <option>Function</option>
                {jobFunctions.map(jobFunction => <option data-slug={jobFunction.name} value={jobFunction.id} key={jobFunction.id}>{jobFunction.name}</option>)}
              </Form.Select>
          }
        </Col>
      </Row>
    </>
  );
}

export default JobsFiltersForFullPage;